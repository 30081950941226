import { yupResolver } from '@hookform/resolvers/yup';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Alert, Divider, Grid, InputLabel } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { AutocompleteSearch } from '../../../components/basics/AutocompleteSearch';
import { SelectOption } from '../../../components/basics/ControlledComboBox';
import { FormMessage } from '../../../models/FormMessage';
import { Product } from '../../../models/Product';
import { ProductLink } from '../../../models/ProductLink';
import { RDProduct } from '../../../models/RDProduct';
import { ProductService } from '../../../services/Product.service';
import { ProductLinkService } from '../../../services/ProductLink.service';
import { RDProductService } from '../../../services/RDProduct.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { ProductLinkSchema } from '../../../utils/forms/validations/formValidations';
import { setInputErrorsFromApi } from '../../../utils/utils';
import { useProductLinksPageContext } from '../context/ProductLinkPageContext';

interface Props {
  editItem?: ProductLink;
  onClose: () => void;
}

export const ProductFormDialog = ({ editItem, onClose }: Props) => {
  const dispatch = useDispatch();
  const [formMessage, setFormMessage] = useState<FormMessage | null>(null);
  const [rdProducts, setRDProducts] = useState<SelectOption[]>([]);
  const [customer, setProduct] = useState<SelectOption[]>([]);
  const { fetchProductLinks } = useProductLinksPageContext();
  const [selectedProduct, setSelectedProduct] = useState<SelectOption>({} as SelectOption);
  const [selectedRDProduct, setSelectedRDProduct] = useState<SelectOption>({} as SelectOption);

  const {
    control,
    handleSubmit,
    setError,
    setValue,
    formState: { errors }
  } = useForm<ProductLink>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(ProductLinkSchema),
    defaultValues: editItem
      ? {
          ...editItem,
          product_detail: {
            code: editItem.product_detail.code,
            description: editItem.product_detail?.description
          } as SelectOption
        }
      : {}
  });
  const onSubmitForm: SubmitHandler<ProductLink> = async (data: ProductLink) => {
    data = {
      ...data,
      rd_product_id: selectedRDProduct?.id,
      rd_product_name: selectedRDProduct?.name,
      itcm_id: selectedProduct?.itcm_id,
      masc_id: selectedProduct?.masc_id
    };
    dispatch(showSpinner());
    try {
      const response = editItem?.id ? await ProductLinkService.update(data) : await ProductLinkService.create(data);
      if (response) {
        dispatch(
          showSnackbarAlert({
            title: 'Sucesso!',
            message: editItem?.id ? 'Vínculo atualizado com sucesso!' : 'Vínculo criado com sucesso!',
            severity: 'success'
          })
        );
      }
      fetchProductLinks();
      onClose();
    } catch (error: any) {
      setFormMessage({
        severity: 'error',
        message: setInputErrorsFromApi(setError, error.data) || ''
      });
    } finally {
      dispatch(hideSpinner());
    }
  };

  const handleSelectCompany = (value: SelectOption) => {
    setSelectedRDProduct(value);
  };
  const handleSelectProduct = (value: SelectOption) => {
    setSelectedProduct(value);
  };
  const fetchRDProducts = async (watchRDProductSearch?: string) => {
    try {
      const response = await RDProductService.get({ search: watchRDProductSearch });
      if (response?.data?.results) {
        const dataRDProducts = response?.data?.results;
        setRDProducts(
          dataRDProducts.map((product: RDProduct) => ({
            id: product.id,
            name: product.name
          }))
        );
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: 'Erro ao buscar Produtos do RD Station',
          severity: 'error'
        })
      );
    }
  };

  const fetchProduct = async (watchProductSearch?: string) => {
    try {
      const response = await ProductService.get({ search: watchProductSearch });
      if (response?.data?.results) {
        const dataProduct = response?.data?.results;
        setProduct(
          dataProduct.map((product: Product) => ({
            id: `${product.itcm_id} - ${product.masc_id}`,
            name: `${product.code} - ${product.description}`,
            itcm_id: product.itcm_id,
            masc_id: product.masc_id
          }))
        );
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: 'Erro ao buscar Produtos Focco',
          severity: 'error'
        })
      );
    }
  };

  useEffect(() => {
    if (editItem?.product_detail) {
      const product = editItem.product_detail;
      setSelectedProduct({
        id: `${product.itcm_id} - ${product.masc_id}`,
        name: `${product.code} - ${product.description}`,
        itcm_id: product.itcm_id,
        masc_id: product.masc_id
      });
      setProduct([{ id: `${product.itcm_id} - ${product.masc_id}`, name: `${product.code} - ${product.description}` }]);
    }
    if (editItem?.rd_product_id) {
      const rdProduct = { id: editItem.rd_product_id, name: editItem.rd_product_name };
      setSelectedRDProduct({
        id: rdProduct.id,
        name: rdProduct.name
      });
      setRDProducts([{ id: rdProduct.id, name: rdProduct.name }]);
    }
  }, [editItem]);

  return (
    <Dialog open={true} onClose={onClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        {editItem?.id ? 'Editar' : 'Novo'} Vínculo de Produto
      </DialogTitle>
      <Divider />
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {formMessage?.message && (
                <Grid item xs={12}>
                  <Alert severity={formMessage?.severity} variant="outlined" sx={{ width: '100%' }}>
                    {formMessage.message}
                  </Alert>
                </Grid>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="product_detail">Produtos Focco</InputLabel>
              <AutocompleteSearch
                value={selectedProduct}
                defaultValue={
                  editItem?.product_detail
                    ? {
                        id: `${editItem.product_detail.itcm_id} - ${editItem.product_detail.masc_id}`,
                        name: `${editItem.product_detail.code} - ${editItem.product_detail.description}`,
                        itcm_id: editItem.product_detail.itcm_id,
                        masc_id: editItem.product_detail.masc_id
                      }
                    : undefined
                }
                onSearch={fetchProduct}
                selectOptions={customer}
                onSelect={(event, value: SelectOption) => handleSelectProduct(value)}
                errorMessage={errors.product_detail?.message}
                getOptionLabel={(option) => option.name}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="rd_product_id">Produtos RD</InputLabel>
              <AutocompleteSearch
                value={selectedRDProduct}
                defaultValue={
                  editItem?.rd_product_id
                    ? {
                        id: editItem.rd_product_id,
                        name: editItem.rd_product_name
                      }
                    : undefined
                }
                onSearch={fetchRDProducts}
                selectOptions={rdProducts}
                onSelect={(event, value: SelectOption) => handleSelectCompany(value)}
                errorMessage={errors.rd_product_id?.message}
                getOptionLabel={(option) => option.name}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ mx: 2, my: 1 }}>
          <Button startIcon={<CloseIcon />} variant="outlined" onClick={onClose}>
            Cancelar
          </Button>
          <Button startIcon={<CheckIcon />} variant="contained" type="submit" onClick={handleSubmit(onSubmitForm)}>
            Salvar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
