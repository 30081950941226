import CloseIcon from '@mui/icons-material/Close';
import { Divider, Grid, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { SaleDivisionLink, SaleDivisionLinkType } from '../../../models/SaleDivisionLink';

interface Props {
  item: SaleDivisionLink;
  onClose: () => void;
}

export const SaleDivisionLinkDetailsDialog = ({ item, onClose }: Props) => {
  return (
    <Dialog open={!!item} onClose={onClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        Detalhes do Vínculo de Divisão de Venda
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" color="secondary" gutterBottom>
              Tipo:
            </Typography>
            <Typography variant="body1" gutterBottom>
              {SaleDivisionLinkType[item.type] || '-'}
            </Typography>
          </Grid>
          {item.type === 'CUSTOMER' && (
            <>
              <Grid item xs={12} md={6}>
                <Typography variant="body1" color="secondary" gutterBottom>
                  Código da Divisão de Venda:
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {item.sale_division.code || '-'}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="body1" color="secondary" gutterBottom>
                  Descrição da Divisão de venda:
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {item.sale_division.description || '-'}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="body1" color="secondary" gutterBottom>
                  Segmento de Mercado:
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {item.market_segment.description || '-'}
                </Typography>
              </Grid>
            </>
          )}
          {item.type === 'PROSPECT' && (
            <>
              <Grid item xs={12} md={6}>
                <Typography variant="body1" color="secondary" gutterBottom>
                  Representante:
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {item.focco_representative.trade_name || '-'}
                </Typography>
              </Grid>
            </>
          )}
          <Grid item xs={12} md={6}>
            <Typography variant="body1" color="secondary" gutterBottom>
              Etapa Funil RD:
            </Typography>
            <Typography variant="body1" gutterBottom>
              {item.funnel_stage_name || '-'}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ mx: 2, my: 1 }}>
        <Button startIcon={<CloseIcon />} variant="outlined" onClick={onClose}>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
