import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Divider, Grid, IconButton, Paper, Popper, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';
import { IntegrationLog, OperationEnum, getModelDisplay } from '../../../models/IntegrationLog';
import { formatRecordDifference } from '../../../utils/formatters/recordDifferenceFormatter';
import { formatDateTime } from '../../../utils/utils';

interface Props {
  item: IntegrationLog;
  onClose: () => void;
}

export const IntegrationLogDetailsDialog: React.FC<Props> = ({ item, onClose }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;
  return (
    <Dialog open={!!item} onClose={onClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        Detalhes da Operação
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" color="secondary" gutterBottom>
              Data:
            </Typography>
            <Typography variant="body1" gutterBottom>
              {formatDateTime(item.operation_time)}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" color="secondary" gutterBottom>
              Operação:
            </Typography>
            <Typography variant="body1" gutterBottom>
              {OperationEnum[item.operation] ? OperationEnum[item.operation] : '-'}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" color="secondary" gutterBottom>
              Objeto:
            </Typography>
            <Typography variant="body1" gutterBottom>
              {getModelDisplay(item.model)}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" color="secondary" gutterBottom>
              Status:
            </Typography>
            <Typography variant="body1" gutterBottom>
              {item.error_traceback ? 'Erro' : 'Sucesso'}
              {item.error_traceback ? (
                <>
                  <IconButton aria-label="delete" onClick={handleClick}>
                    <ErrorOutlineIcon />
                  </IconButton>

                  <Popper id={id} open={open} anchorEl={anchorEl} sx={{ zIndex: 1301, maxWidth: '50%' }}>
                    <Paper>
                      <Typography sx={{ p: 2 }}>Erro: {item.error_traceback}</Typography>
                    </Paper>
                  </Popper>
                </>
              ) : (
                ''
              )}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" color="secondary" gutterBottom>
              Alterações:
            </Typography>

            {formatRecordDifference(item.operation, item.record_difference)}
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ mx: 2, my: 1 }}>
        <Button startIcon={<CloseIcon />} variant="outlined" onClick={onClose}>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
