import { yupResolver } from '@hookform/resolvers/yup';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Alert, Autocomplete, Divider, FormHelperText, Grid, InputLabel, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { AutocompleteSearch } from '../../../components/basics/AutocompleteSearch';
import { ControlledComboBox, SelectOption } from '../../../components/basics/ControlledComboBox';
import { FormMessage } from '../../../models/FormMessage';
import { MarketSegment } from '../../../models/MarketSegment';
import { RDFunnel } from '../../../models/RDFunnel';
import { RDFunnelStage } from '../../../models/RDFunnelStage';
import { Representative } from '../../../models/Representative';
import { SaleDivision } from '../../../models/SaleDivision';
import { SaleDivisionLink } from '../../../models/SaleDivisionLink';
import { MarketSegmentService } from '../../../services/MarketSegment.service';
import { RDFunnelsService } from '../../../services/RDFunnel.service';
import { RepresentativesService } from '../../../services/Representative.service';
import { SaleDivisionService } from '../../../services/SaleDivision.service';
import { SaleDivisionLinkService } from '../../../services/SaleDivisionLink.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { SaleDivisionLinkSchema } from '../../../utils/forms/validations/formValidations';
import { findSelectedOption, setInputErrorsFromApi } from '../../../utils/utils';
import { useSaleDivisionLinksPageContext } from '../context/SaleDivisionLinkPageContext';

interface Props {
  editItem?: SaleDivisionLink;
  onClose: () => void;
}

export const SaleDivisionFormDialog = ({ editItem, onClose }: Props) => {
  const dispatch = useDispatch();
  const [formMessage, setFormMessage] = useState<FormMessage | null>(null);
  const [rdFunnels, setRDFunnels] = useState<RDFunnel[]>([]);
  const [salesDivision, setSaleDivision] = useState<SelectOption[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedMarketSegment, setSelectedMarketSegment] = useState<SelectOption>({} as SelectOption);
  const [selectedSaleDivision, setSelectedSaleDivision] = useState<SelectOption>({} as SelectOption);
  const [selectedRepresentative, setSelectedRepresentative] = useState<SelectOption>({} as SelectOption);
  const [marketSegments, setMarketSegments] = useState<SelectOption[]>([]);
  const [representatives, setRepresentatives] = useState<SelectOption[]>([]);
  const [selectedFunnelStage, setSelectedFunnelStage] = useState<RDFunnelStage | null>(null);
  const typeOptions: SelectOption[] = [
    { id: 'CUSTOMER', name: 'Cliente' },
    { id: 'PROSPECT', name: 'Prospect' }
  ];

  const { fetchSaleDivisionLinks } = useSaleDivisionLinksPageContext();

  const {
    control,
    handleSubmit,
    setError,
    setValue,
    formState: { errors }
  } = useForm<SaleDivisionLink>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(SaleDivisionLinkSchema),
    defaultValues: editItem
      ? { ...editItem, type: findSelectedOption(typeOptions, editItem?.type) }
      : {
          funnel_stage: null
        }
  });
  const selectedType = useWatch({
    control,
    name: 'type'
  });

  const onSubmitForm: SubmitHandler<SaleDivisionLink> = async (data: SaleDivisionLink) => {
    data = {
      ...data,
      type: data.type ? data.type?.id : null,
      funnel: selectedFunnelStage ? selectedFunnelStage.funnelId : null,
      funnel_stage: selectedFunnelStage ? selectedFunnelStage.stageId : null,
      funnel_stage_name: selectedFunnelStage ? selectedFunnelStage.stageName : null,
      sale_division: selectedSaleDivision?.id,
      market_segment: selectedMarketSegment?.id,
      focco_representative: selectedRepresentative.id
    };
    dispatch(showSpinner());
    try {
      const response = editItem?.id ? await SaleDivisionLinkService.update(data) : await SaleDivisionLinkService.create(data);
      if (response) {
        dispatch(
          showSnackbarAlert({
            title: 'Sucesso!',
            message: editItem?.id ? 'Vínculo atualizado com sucesso!' : 'Vínculo criado com sucesso!',
            severity: 'success'
          })
        );
      }
      fetchSaleDivisionLinks();
      onClose();
    } catch (error: any) {
      setFormMessage({
        severity: 'error',
        message: setInputErrorsFromApi(setError, error.data) || ''
      });
    } finally {
      dispatch(hideSpinner());
    }
  };

  const handleSelectMarketSegment = (value: SelectOption) => {
    setSelectedMarketSegment(value);
  };

  const handleSelectSaleDivision = (value: SelectOption) => {
    setSelectedSaleDivision(value);
  };

  const handleSelectRepresentative = (value: SelectOption) => {
    setSelectedRepresentative(value);
  };

  const fetchRepresentative = async (watchRepresentativeSearch?: string) => {
    try {
      const response = await RepresentativesService.get({ search: watchRepresentativeSearch });
      if (response?.data?.results) {
        const dataRepresentatives = response?.data?.results;
        setRepresentatives(
          dataRepresentatives.map((representative: Representative) => ({
            id: representative.id,
            name: `${representative.code} - ${representative.description}`,
            cnpj: representative.cnpj,
            cpf: representative.cpf
          }))
        );
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: 'Erro ao buscar Representantes Focco',
          severity: 'error'
        })
      );
    }
  };
  const fetchRDFunnels = async () => {
    try {
      const response = await RDFunnelsService.getAll();
      if (response?.data?.results) {
        const dataRDFunnels = response.data.results;
        setRDFunnels(
          dataRDFunnels.flatMap((funnel: RDFunnel) =>
            funnel.deal_stages.map((stage: RDFunnelStage) => ({
              funnelName: funnel.name,
              funnelId: funnel.id,
              stageName: stage.name,
              stageId: stage.id
            }))
          )
        );
      }
    } catch (error) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: 'Erro ao buscar Funis e Etapas do RD Station',
          severity: 'error'
        })
      );
    }
  };

  const fetchMarketSegment = async (watchMarketSegmentSearch?: string) => {
    try {
      const response = await MarketSegmentService.get({ search: watchMarketSegmentSearch });
      if (response?.data?.results) {
        const dataMarketSegments = response?.data?.results;
        setMarketSegments(
          dataMarketSegments.map((market_segment: MarketSegment) => ({
            id: market_segment.id,
            name: `${market_segment.code} - ${market_segment.description}`
          }))
        );
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: 'Erro ao buscar Segmentos de Mercado',
          severity: 'error'
        })
      );
    }
  };

  const fetchSaleDivision = async (watchSaleDivisionSearch?: string) => {
    try {
      const response = await SaleDivisionService.get({ search: watchSaleDivisionSearch });
      if (response?.data?.results) {
        const dataSaleDivision = response?.data?.results;
        setSaleDivision(
          dataSaleDivision.map((sale_division: SaleDivision) => ({
            id: sale_division.id,
            name: `${sale_division.code} - ${sale_division.description}`
          }))
        );
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: 'Erro ao buscar Divisões de Venda Focco',
          severity: 'error'
        })
      );
    }
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      dispatch(showSpinner());

      await fetchRDFunnels();

      setIsLoading(false);
      dispatch(hideSpinner());
    })();
  }, []);

  useEffect(() => {
    if (editItem && rdFunnels.length > 0) {
      const funnelStage = rdFunnels.find((stage) => stage.stageId === editItem.funnel_stage);
      if (funnelStage) {
        setValue('funnel_stage', funnelStage);
        setSelectedFunnelStage(funnelStage);
      }
    }
  }, [rdFunnels]);

  useEffect(() => {
    if (editItem?.market_segment) {
      const market_segment = editItem.market_segment as MarketSegment;
      setSelectedMarketSegment({ id: market_segment.id, name: `${market_segment.code} - ${market_segment.description}` });
      setMarketSegments([{ id: market_segment.id, name: `${market_segment.code} - ${market_segment.description}` }]);
    }
    if (editItem?.sale_division) {
      const sale_division = editItem.sale_division as SaleDivision;
      setSelectedSaleDivision({ id: sale_division.id, name: `${sale_division.code} - ${sale_division.description}` });
      setSaleDivision([{ id: sale_division.id, name: `${sale_division.code} - ${sale_division.description}` }]);
    }
    if (editItem?.focco_representative) {
      const representative = editItem.focco_representative as Representative;
      setSelectedRepresentative({ id: representative.id, name: `${representative.code} - ${representative.description}` });
      setRepresentatives([{ id: representative.id, name: `${representative.code} - ${representative.description}` }]);
    }
  }, [editItem]);

  return (
    <Dialog open={!isLoading} onClose={onClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        {editItem?.id ? 'Editar' : 'Novo'} Vínculo de Divisão de Venda
      </DialogTitle>
      <Divider />
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {formMessage?.message && (
                <Grid item xs={12}>
                  <Alert severity={formMessage?.severity} variant="outlined" sx={{ width: '100%' }}>
                    {formMessage.message}
                  </Alert>
                </Grid>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="type">Tipo</InputLabel>
              <ControlledComboBox
                name="type"
                control={control}
                errorMessage={errors.type?.message?.toString()}
                selectOptions={typeOptions}
              />
            </Grid>
            {selectedType?.id === 'CUSTOMER' && (
              <>
                <Grid item xs={12} md={6}>
                  <InputLabel htmlFor="sale_division">Divisão de Venda</InputLabel>
                  <AutocompleteSearch
                    value={selectedSaleDivision}
                    defaultValue={
                      editItem?.sale_division
                        ? {
                            id: editItem.sale_division.id,
                            name: `${editItem.sale_division.code} - ${editItem.sale_division.description}`
                          }
                        : undefined
                    }
                    onSearch={fetchSaleDivision}
                    selectOptions={salesDivision}
                    onSelect={(event, value: SelectOption) => handleSelectSaleDivision(value)}
                    errorMessage={errors.sale_division?.message}
                    getOptionLabel={(option) => option.name}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputLabel htmlFor="market_segment">Segmento de Mercado</InputLabel>
                  <AutocompleteSearch
                    value={selectedMarketSegment}
                    defaultValue={
                      editItem?.market_segment
                        ? {
                            id: editItem.market_segment.id,
                            name: `${editItem.market_segment.code} - ${editItem.market_segment.description}`
                          }
                        : undefined
                    }
                    onSearch={fetchMarketSegment}
                    selectOptions={marketSegments}
                    onSelect={(event, value: SelectOption) => handleSelectMarketSegment(value)}
                    errorMessage={errors.market_segment?.message}
                    getOptionLabel={(option) => option.name}
                  />
                </Grid>
              </>
            )}
            {selectedType?.id === 'PROSPECT' && (
              <Grid item xs={12} md={6}>
                <InputLabel htmlFor="focco_representative">Representantes</InputLabel>
                <AutocompleteSearch
                  value={selectedRepresentative}
                  defaultValue={
                    editItem?.focco_representative
                      ? {
                          id: editItem.focco_representative.id,
                          name: `${editItem.focco_representative.code} - ${editItem.focco_representative.description}`
                        }
                      : undefined
                  }
                  onSearch={fetchRepresentative}
                  selectOptions={representatives}
                  onSelect={(event, value: SelectOption) => handleSelectRepresentative(value)}
                  errorMessage={errors.focco_representative?.message}
                  getOptionLabel={(option) =>
                    `${option.name} ${option.cnpj ? ` - CNPJ: ${option.cnpj}` : ''} ${option.cpf ? ` - CPF: ${option.cpf}` : ''}`
                  }
                />
              </Grid>
            )}
            {selectedType && (
              <Grid item xs={12} md={6}>
                <InputLabel htmlFor="funnel_stage">Etapa do Funil</InputLabel>
                <Controller
                  name="funnel_stage"
                  control={control}
                  render={({ field: { onChange, value, ref } }) => (
                    <>
                      <Autocomplete
                        name="funnel_stage"
                        id="grouped-demo"
                        size="small"
                        options={rdFunnels.sort((a, b) => a.funnelName.localeCompare(b.funnelName))}
                        groupBy={(option) => option.funnelName}
                        getOptionLabel={(option) => option.stageName}
                        value={value}
                        onChange={(event, newValue) => {
                          onChange(newValue);
                          setSelectedFunnelStage(newValue);
                        }}
                        sx={{ width: '100%' }}
                        renderInput={(params) => <TextField {...params} inputRef={ref} />}
                      />
                      {errors.funnel_stage && <FormHelperText sx={{ color: 'red' }}>{errors.funnel_stage.message}</FormHelperText>}
                    </>
                  )}
                />
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ mx: 2, my: 1 }}>
          <Button startIcon={<CloseIcon />} variant="outlined" onClick={onClose}>
            Cancelar
          </Button>
          <Button startIcon={<CheckIcon />} variant="contained" type="submit" onClick={handleSubmit(onSubmitForm)}>
            Salvar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
