import { BaseReadOnlyService } from './Base.service';

export class RevenueByMonthService extends BaseReadOnlyService {
  baseUrl = '/reports/revenue-by-month/';
}

export class RevenueByChannelService extends BaseReadOnlyService {
  baseUrl = '/reports/revenue-by-channel/';
}

export class NetRevenueByChannelService extends BaseReadOnlyService {
  baseUrl = '/reports/net-revenue-by-channel/';
}

export class RevenueEquipmentByMonthService extends BaseReadOnlyService {
  baseUrl = '/reports/revenue-equipment-by-month/';
}

export class EquipmentRepresentationByChannelService extends BaseReadOnlyService {
  baseUrl = '/reports/equipment-representation-by-channel/';
}

export class EquipmentRepresentationByValueService extends BaseReadOnlyService {
  baseUrl = '/reports/equipment-representation-by-value/';
}

export class NetRevenueByChannelAndMonthService extends BaseReadOnlyService {
  baseUrl = '/reports/net-revenue-by-channel-and-month/';
}

export class AvgTicketWithConsolidatedQuantityService extends BaseReadOnlyService {
  baseUrl = '/reports/avg-ticket-with-consolidated-quantity/';
}

export class ChannelAvgTicketWithConsolidatedQuantityService extends BaseReadOnlyService {
  baseUrl = '/reports/channel-avg-ticket-with-consolidated-quantity/';
}

export class MarkupByMonthService extends BaseReadOnlyService {
  baseUrl = '/reports/markup-by-month/';
}

export class MarkupByChannelMonthService extends BaseReadOnlyService {
  baseUrl = '/reports/markup-by-channel-month/';
}
