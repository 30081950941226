import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BaseEcharts } from '../../../components/charts/BaseEcharts';
import { EquipmentRepresentationByValueService } from '../../../services/Reports.service';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { formatValueToBRL } from '../utils';

export const EquipmentRepresentationByValue = () => {
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();

  const options = {
    tooltip: {
      trigger: 'item',
      axisPointer: { type: 'shadow' },
      formatter: function (params: any) {
        return `${params.marker} ${params.name}<br/><b>${formatValueToBRL(params.data.value)}</b> <br/><b>${params.percent}%</b>`;
      }
    },
    label: {
      formatter: function (params: any) {
        return `{name|${params.name}}\n{value|${formatValueToBRL(params.value)}}\n{value|${params.percent}%}`;
      },
      rich: {
        value: {
          color: '#4D4D4D'
        },
        name: {
          fontWeight: 'bold'
        }
      }
    },
    series: [
      {
        name: 'Canal',
        type: 'pie',
        padding: 10,
        data: data.map((item) => {
          return { value: item.value, name: item.channel };
        }),
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }
    ]
  };

  const fetchReport = async () => {
    setLoading(true);
    try {
      const response = await new EquipmentRepresentationByValueService().listAll();
      if (response?.data?.results) {
        setData(response.data.results.report);
      }
    } catch (error: any) {
      const errorMessage = error?.data?.detail || 'Erro ao buscar dados do Gráfico';
      dispatch(
        showSnackbarAlert({
          title: 'Erro!',
          message: errorMessage,
          severity: 'error'
        })
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchReport();
  }, []);

  return (
    <BaseEcharts
      title='Representatividade de Equipamentos Faturados / Canal - Valor'
      option={options}
      dataLength={data.length}
      loading={loading}
      style={{ height: 400 }}
    />
  );
};
