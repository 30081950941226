import { Container } from '@mui/material';
import { TabsComponent } from '../../../sections/@user-profile/TabsComponent';
import { UserCoverComponent } from '../../../sections/@user-profile/UserCoverComponent';
import { UserTabsContextProvider } from '../../../sections/@user-profile/context/UserTabsContext';
import { HelmetMeta } from '../../../layout/HelmetMeta';

export const ProfilePageWrapper = () => (
  <Container>
    <HelmetMeta pageName="Perfil" />
    <UserTabsContextProvider>
      <UserCoverComponent />
      <TabsComponent />
    </UserTabsContextProvider>
  </Container>
);
