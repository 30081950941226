import CheckIcon from '@mui/icons-material/Check';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import CloseIcon from '@mui/icons-material/Close';
import { Divider, Grid, InputLabel } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { ControlledComboBox, SelectOption } from '../../../components/basics/ControlledComboBox';
import { ControlledTextInput } from '../../../components/basics/ControlledTextInput';
import { RDUser } from '../../../models/RDUser';
import { RDUSersService } from '../../../services/RDUser.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { RepresentativeLinkFilterFields, useRepresentativeLinksPageContext } from '../context/RepresentativeLinkPageContext';
interface Props {
  onClose: () => void;
}

const getDefaultValues = (filter?: RepresentativeLinkFilterFields): RepresentativeLinkFilterFields => {
  return {
    focco_representative_code: filter?.focco_representative_code ?? '',
    focco_representative_description: filter?.focco_representative_description ?? '',
    focco_representative_trade_name: filter?.focco_representative_trade_name ?? '',
    focco_representative_cnpj: filter?.focco_representative_cnpj ?? '',
    focco_representative_cpf: filter?.focco_representative_cpf ?? ''
  };
};

export const UsersFilterDialog = ({ onClose }: Props) => {
  const { filterFields, setFilterFields } = useRepresentativeLinksPageContext();
  const dispatch = useDispatch();
  const [rdUsers, setRDUsers] = useState<SelectOption[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors }
  } = useForm<RepresentativeLinkFilterFields>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: getDefaultValues(filterFields)
  });

  const onSubmit: SubmitHandler<RepresentativeLinkFilterFields> = async (data: RepresentativeLinkFilterFields) => {
    data = {
      ...data,
      rd_user: data?.rd_user ? data.rd_user.id : null
    };
    setFilterFields({ ...data } as RepresentativeLinkFilterFields);
    onClose();
  };

  const handleClear = () => {
    setFilterFields({} as RepresentativeLinkFilterFields);
    onClose();
  };

  const fetchRDUsers = async () => {
    try {
      const response = await RDUSersService.getAll();
      if (response?.data?.results) {
        const dataRDUsers = response?.data?.results;
        setRDUsers(
          dataRDUsers.map((user: RDUser) => ({
            id: user.id,
            name: user.name
          }))
        );
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: 'Erro ao buscar Usuários do RD Station',
          severity: 'error'
        })
      );
    }
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      dispatch(showSpinner());

      await fetchRDUsers();

      setIsLoading(false);
      dispatch(hideSpinner());
    })();
  }, []);

  useEffect(() => {
    if (filterFields && filterFields.rd_user) {
      const selectedOption = rdUsers.find((option) => option.id === filterFields.rd_user);
      if (selectedOption) {
        setValue('rd_user', selectedOption);
      }
    }
  }, [rdUsers]);

  return (
    <Dialog open={!isLoading} onClose={onClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        Filtros
      </DialogTitle>
      <Divider />
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="focco_representative_code">Código Representante</InputLabel>
              <ControlledTextInput
                name="focco_representative_code"
                placeholder="Código Representante"
                control={control}
                errorMessage={errors.focco_representative?.code?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="focco_representative_description">Nome Representante</InputLabel>
              <ControlledTextInput
                name="focco_representative_description"
                placeholder="Nome Representante"
                control={control}
                errorMessage={errors.focco_representative?.description?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="focco_representative_trade_name">Nome Fantasia</InputLabel>
              <ControlledTextInput
                name="focco_representative_trade_name"
                placeholder="Nome Fantasia"
                control={control}
                errorMessage={errors.focco_representative?.trade_name?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="focco_representative_cnpj">CNPJ</InputLabel>
              <ControlledTextInput
                name="focco_representative_cnpj"
                placeholder="CNPJ"
                control={control}
                errorMessage={errors.focco_representative?.cnpj?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="focco_representative_cnpj">CPF</InputLabel>
              <ControlledTextInput
                name="focco_representative_cpf"
                placeholder="CPF"
                control={control}
                errorMessage={errors.focco_representative?.cpf?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="rd_user">Usuários RD</InputLabel>
              <ControlledComboBox
                placeholder="Usuários RD"
                name="rd_user"
                control={control}
                errorMessage={errors.rd_user?.message?.toString()}
                selectOptions={rdUsers}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ mx: 2, my: 1 }}>
          <Button startIcon={<ClearAllIcon />} variant="outlined" onClick={handleClear}>
            Limpar
          </Button>
          <Button startIcon={<CloseIcon />} variant="outlined" onClick={onClose}>
            Fechar
          </Button>
          <Button startIcon={<CheckIcon />} variant="contained" type="submit" onClick={handleSubmit(onSubmit)}>
            Filtrar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
