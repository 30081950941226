import { HelmetMeta } from '../../../layout/HelmetMeta';
import { AvailabilityPage } from '../../../sections/@availability-page/AvailabilityPage';
import { AvailabilityPageContextProvider } from '../../../sections/@availability-page/context/AvailabilityPageContext';

export const AvailabilityPageWrapper = () => (
  <AvailabilityPageContextProvider>
    <HelmetMeta pageName="Disponibilidade" />
    <AvailabilityPage />
  </AvailabilityPageContextProvider>
);
