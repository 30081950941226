import { HelmetMeta } from '../../../layout/HelmetMeta';
import { LinkRepresentativePage } from '../../../sections/@link-representative/LinkRepresentativePage';
import { LinkRepresentativePageContextProvider } from '../../../sections/@link-representative/context/RepresentativeLinkPageContext';

export const LinkRepresentativePageWrapper = () => (
  <LinkRepresentativePageContextProvider>
    <HelmetMeta pageName="Vinculo de Representante" />
    <LinkRepresentativePage />
  </LinkRepresentativePageContextProvider>
);
