import React, { createContext, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ApiResults } from '../../../models/ApiResults';
import { ProductLink } from '../../../models/ProductLink';
import { ProductLinkService } from '../../../services/ProductLink.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { checkResponseStatus } from '../../../utils/api/response';

export interface ProductLinkFilterFields extends ProductLink {
  order_by?: string;
  page?: number;
  page_size?: number;
  search?: string;
  product_code?: string;
  product_name?: string;
}

export interface ProductLinkResult extends ApiResults {
  results: ProductLink[];
}

interface ProductLinkContextProps {
  productLinks: ProductLinkResult | undefined;
  fetchProductLinks: () => void;
  deleteProductLinks: (id: number) => Promise<void>;
  deleteManyProductLinks: (items: string[]) => Promise<void>;
  filterFields: ProductLinkFilterFields;
  setFilterFields: React.Dispatch<React.SetStateAction<ProductLinkFilterFields>>;
  page: number;
  rowsPerPage: number;
  handleOrderChange: (property: string, order: 'asc' | 'desc') => void;
  handlePageChange: (page: number) => void;
  handleOnChangeRowsPerPage: (page_size: number) => void;
}

const ProductLinksPageContext = createContext<ProductLinkContextProps | undefined>(undefined);

interface Props {
  children: React.ReactNode;
}

export const ProductLinkPageContextProvider = ({ children }: Props) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(50);
  const [productLinks, setProductLinks] = useState<ProductLinkResult | undefined>(undefined);
  const [filterFields, setFilterFields] = useState<ProductLinkFilterFields>({} as ProductLinkFilterFields);

  const fetchProductLinks = async () => {
    dispatch(showSpinner());

    try {
      const response = await ProductLinkService.get(filterFields, page, rowsPerPage);
      if (checkResponseStatus(response)) {
        setProductLinks(response?.data);
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({ title: 'Erro', message: error?.data || 'Houve um erro ao processar a sua solicitação', severity: 'error' })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const deleteProductLinks = async (id: number) => {
    dispatch(showSpinner());
    try {
      await ProductLinkService.delete(id);
      dispatch(
        showSnackbarAlert({
          title: 'Sucesso',
          message: 'Registro excluído com sucesso!',
          severity: 'success'
        })
      );
      fetchProductLinks();
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: error.data.detail || 'Houve um erro ao processar a sua solicitação',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const deleteManyProductLinks = async (items: string[]) => {
    dispatch(showSpinner());
    try {
      await ProductLinkService.deleteMany(items);
      dispatch(
        showSnackbarAlert({
          title: 'Sucesso',
          message: 'Registros excluídos com sucesso!',
          severity: 'success'
        })
      );
      fetchProductLinks();
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: error.data.detail ?? 'Houve um erro ao processar a sua solicitação',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const handleOrderChange = (property: string, order: 'asc' | 'desc') => {
    const orderFilter = order === 'asc' ? property : `-${property}`;
    setFilterFields({ ...filterFields, order_by: orderFilter });
  };

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handleOnChangeRowsPerPage = (page_size: number) => {
    setRowsPerPage(page_size);
  };

  useEffect(() => {
    fetchProductLinks();
  }, [filterFields, page, rowsPerPage]);

  const contextValue: ProductLinkContextProps = {
    productLinks,
    fetchProductLinks,
    deleteProductLinks,
    deleteManyProductLinks,
    filterFields,
    setFilterFields,
    page,
    rowsPerPage,
    handleOrderChange,
    handlePageChange,
    handleOnChangeRowsPerPage
  };

  return <ProductLinksPageContext.Provider value={contextValue}>{children}</ProductLinksPageContext.Provider>;
};

export const useProductLinksPageContext = () => {
  const context = useContext(ProductLinksPageContext);
  if (!context) {
    throw new Error('useProductLinksPageContext must be used within a ProductLinksPageContextProvider');
  }
  return context;
};
