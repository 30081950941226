import CheckIcon from '@mui/icons-material/Check';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import CloseIcon from '@mui/icons-material/Close';
import { Divider, Grid, InputLabel } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { ControlledComboBox, SelectOption } from '../../../components/basics/ControlledComboBox';
import { ControlledTextInput } from '../../../components/basics/ControlledTextInput';
import { RDUser } from '../../../models/RDUser';
import { RDProductService } from '../../../services/RDProduct.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { ProductLinkFilterFields, useProductLinksPageContext } from '../context/ProductLinkPageContext';
interface Props {
  onClose: () => void;
}

const getDefaultValues = (filter?: ProductLinkFilterFields): ProductLinkFilterFields => {
  return {
    product_code: filter?.product_code ?? '',
    product_name: filter?.product_name ?? ''
  };
};

export const ProductLinkFilterDialog = ({ onClose }: Props) => {
  const dispatch = useDispatch();
  const { filterFields, setFilterFields } = useProductLinksPageContext();
  const [rdProducts, setRDProducts] = useState<SelectOption[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors }
  } = useForm<ProductLinkFilterFields>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: getDefaultValues(filterFields)
  });

  const onSubmit: SubmitHandler<ProductLinkFilterFields> = async (data: ProductLinkFilterFields) => {
    data = {
      ...data,
      rd_product_id: data?.rd_product_id ? data.rd_product_id.id : null
    };
    setFilterFields({ ...data } as ProductLinkFilterFields);
    onClose();
  };

  const handleClear = () => {
    setFilterFields({} as ProductLinkFilterFields);
    onClose();
  };

  const fetchRDProducts = async () => {
    try {
      const response = await RDProductService.getAll();
      if (response?.data?.results) {
        const dataRDProducts = response?.data?.results;
        setRDProducts(
          dataRDProducts.map((user: RDUser) => ({
            id: user.id,
            name: user.name
          }))
        );
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: 'Erro ao buscar Produtos do RD Station',
          severity: 'error'
        })
      );
    }
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      dispatch(showSpinner());

      await fetchRDProducts();

      setIsLoading(false);
      dispatch(hideSpinner());
    })();
  }, []);

  useEffect(() => {
    if (filterFields && filterFields.rd_product_id) {
      const selectedOption = rdProducts.find((option) => option.id === filterFields.rd_product_id);
      if (selectedOption) {
        setValue('rd_product_id', selectedOption);
      }
    }
  }, [rdProducts]);

  return (
    <Dialog open={!isLoading} onClose={onClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        Filtros
      </DialogTitle>
      <Divider />
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="product_code">Código do Produto</InputLabel>
              <ControlledTextInput
                name="product_code"
                placeholder="Código do Produto"
                control={control}
                errorMessage={errors.product_detail?.code?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="product_name">Descrição do Produto</InputLabel>
              <ControlledTextInput
                name="product_name"
                placeholder="Descrição do Produto"
                control={control}
                errorMessage={errors.product_detail?.description?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="rd_product_id">Produto RD</InputLabel>
              <ControlledComboBox
                name="rd_product_id"
                placeholder="Produto RD"
                control={control}
                errorMessage={errors.rd_product_id?.message?.toString()}
                selectOptions={rdProducts}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ mx: 2, my: 1 }}>
          <Button startIcon={<ClearAllIcon />} variant="outlined" onClick={handleClear}>
            Limpar
          </Button>
          <Button startIcon={<CloseIcon />} variant="outlined" onClick={onClose}>
            Fechar
          </Button>
          <Button startIcon={<CheckIcon />} variant="contained" type="submit" onClick={handleSubmit(onSubmit)}>
            Filtrar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
