import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import { StyledTableRow } from '../../../components/basics/StyledTableRow';
import { convertISOToDate, formatCurrency } from '../../../utils/utils';
import { usePositionAvailabilityValuesContext } from '../context/PositionAvailabilityValuePageContext';

export const PositionAvailabilityTable = () => {
  const { positionAvailabilityValues } = usePositionAvailabilityValuesContext();
  const isEmpty = !positionAvailabilityValues || Object.keys(positionAvailabilityValues).length === 0;

  const months = isEmpty ? [] : Object.keys(positionAvailabilityValues.total_assets || {});

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table sx={{ minWidth: 750, borderCollapse: 'separate', borderSpacing: '0px' }} size="small">
            {!isEmpty && (
              <TableHead>
                <StyledTableRow style={{ backgroundColor: '#d9d9d9' }}>
                  <TableCell colSpan={2} align="center" sx={{ fontWeight: 'bold', fontSize: '13px' }}>
                    Disponibilidades
                  </TableCell>
                  <TableCell colSpan={months.length} />
                </StyledTableRow>
                <StyledTableRow style={{ backgroundColor: '#d9d9d9' }}>
                  <TableCell align="left" sx={{ fontWeight: 'bold', fontSize: '13px' }}>
                    Descrição
                  </TableCell>
                  <TableCell align="left" sx={{ fontWeight: 'bold', fontSize: '13px' }}>
                    Observação
                  </TableCell>
                  {months.map((month) => (
                    <TableCell align="center" key={month} sx={{ fontWeight: 'bold', fontSize: '13px' }}>
                      {convertISOToDate(month)}
                    </TableCell>
                  ))}
                </StyledTableRow>
              </TableHead>
            )}
            <TableBody>
              {isEmpty ? (
                <StyledTableRow>
                  <TableCell align="center" colSpan={months.length + 2}>
                    Nenhum registro encontrado
                  </TableCell>
                </StyledTableRow>
              ) : (
                <>
                  {positionAvailabilityValues.prev_assets.map((item, index) => (
                    <StyledTableRow key={index}>
                      <TableCell align="left" sx={{ fontSize: '12px' }}>
                        {item.description}
                      </TableCell>
                      <TableCell align="left" sx={{ fontSize: '12px' }}>
                        {item.observation}
                      </TableCell>
                      {months.map((month) => (
                        <TableCell align="center" key={month} sx={{ fontSize: '12px' }}>
                          {item.totals[month] ? formatCurrency(item.totals[month]) : '-'}
                        </TableCell>
                      ))}
                    </StyledTableRow>
                  ))}
                  <StyledTableRow style={{ backgroundColor: '#c3c3c3' }}>
                    <TableCell align="left" sx={{ fontSize: '12px', fontWeight: 'bold' }}>
                      Total de Ativos
                    </TableCell>
                    <TableCell colSpan={1} />
                    {months.map((month) => (
                      <TableCell align="center" key={month} sx={{ fontSize: '12px', fontWeight: 'bold' }}>
                        {positionAvailabilityValues.total_assets[month]
                          ? formatCurrency(positionAvailabilityValues.total_assets[month])
                          : '-'}
                      </TableCell>
                    ))}
                  </StyledTableRow>
                  {positionAvailabilityValues.pos_assets.map((item, index) => (
                    <StyledTableRow key={index}>
                      <TableCell align="left" sx={{ fontSize: '12px' }}>
                        {item.description}
                      </TableCell>
                      <TableCell align="left" sx={{ fontSize: '12px' }}>
                        {item.observation}
                      </TableCell>
                      {months.map((month) => (
                        <TableCell align="center" key={month} sx={{ fontSize: '12px' }}>
                          {item.totals[month] ? formatCurrency(item.totals[month]) : '-'}
                        </TableCell>
                      ))}
                    </StyledTableRow>
                  ))}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};
