import { yupResolver } from '@hookform/resolvers/yup';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Alert, Divider, Grid, InputLabel, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from 'react';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { ControlledCheckbox } from '../../../components/basics/ControlledCheckbox';
import { ControlledTextInput } from '../../../components/basics/ControlledTextInput';
import { Availability } from '../../../models/Availability';
import { FormMessage } from '../../../models/FormMessage';
import { AvailabilityService } from '../../../services/Availability.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { AvailabilityScheme } from '../../../utils/forms/validations/formValidations';
import { setInputErrorsFromApi } from '../../../utils/utils';
import { useAvailabilitysPageContext } from '../context/AvailabilityPageContext';

interface Props {
  editItem?: Availability;
  onClose: () => void;
}

export const AvailabilityFormDialog = ({ editItem, onClose }: Props) => {
  const dispatch = useDispatch();
  const [formMessage, setFormMessage] = useState<FormMessage | null>(null);

  const { fetchAvailabilities } = useAvailabilitysPageContext();

  const {
    control,
    handleSubmit,
    setError,
    getValues,
    formState: { errors }
  } = useForm<Availability>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(AvailabilityScheme),
    defaultValues: editItem
      ? {
          ...editItem,
          situation: editItem.situation == 'A' ? true : false
        }
      : {
          situation: true,
          sum_assets: true,
          prev_assets: true
        }
  });
  const onSubmitForm: SubmitHandler<Availability> = async (data: Availability) => {
    data = {
      ...data,
      situation: data?.situation ? 'A' : 'I'
    };
    dispatch(showSpinner());

    try {
      const response = editItem?.id ? await AvailabilityService.update(data) : await AvailabilityService.create(data);
      if (response) {
        dispatch(
          showSnackbarAlert({
            title: 'Sucesso!',
            message: editItem?.id ? 'Disponibilidade atualizada com sucesso!' : 'Disponibilidade criada com sucesso!',
            severity: 'success'
          })
        );
      }
      fetchAvailabilities();
      onClose();
    } catch (error: any) {
      setFormMessage({
        severity: 'error',
        message: setInputErrorsFromApi(setError, error.data) || ''
      });
    } finally {
      dispatch(hideSpinner());
    }
  };
  const watchStatus = useWatch({
    control,
    name: 'situation',
    defaultValue: getValues('situation')
  });

  const watchSumAssets = useWatch({
    control,
    name: 'sum_assets',
    defaultValue: getValues('sum_assets')
  });

  const watchPrevAssets = useWatch({
    control,
    name: 'prev_assets',
    defaultValue: getValues('prev_assets')
  });

  return (
    <Dialog open={true} onClose={onClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        {editItem?.id ? 'Editar' : 'Nova'} Disponibilidade
      </DialogTitle>
      <Divider />
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {formMessage?.message && (
                <Grid item xs={12}>
                  <Alert severity={formMessage?.severity} variant="outlined" sx={{ width: '100%' }}>
                    {formMessage.message}
                  </Alert>
                </Grid>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="description">Descrição</InputLabel>
              <ControlledTextInput
                name="description"
                placeholder="Descrição"
                control={control}
                errorMessage={errors.description?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="observation">Observação</InputLabel>
              <ControlledTextInput
                name="observation"
                placeholder="Observação"
                control={control}
                errorMessage={errors.observation?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="order">Ordem</InputLabel>
              <ControlledTextInput
                placeholder="Ordem"
                name="order"
                control={control}
                errorMessage={errors.order?.message?.toString()}
                type="number"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant="body1">Situação:</Typography>
              <ControlledCheckbox name="situation" label={watchStatus ? 'Ativo' : 'Inativo'} control={control} />
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant="body1">Soma Ativos:</Typography>
              <ControlledCheckbox name="sum_assets" label={watchSumAssets ? 'Sim' : 'Não'} control={control} />
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant="body1">Exibir Antes dos Ativos:</Typography>
              <ControlledCheckbox name="prev_assets" label={watchPrevAssets ? 'Sim' : 'Não'} control={control} />
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ mx: 2, my: 1 }}>
          <Button startIcon={<CloseIcon />} variant="outlined" onClick={onClose}>
            Cancelar
          </Button>
          <Button startIcon={<CheckIcon />} variant="contained" type="submit" onClick={handleSubmit(onSubmitForm)}>
            Salvar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
