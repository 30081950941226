import { HelmetMeta } from '../../../layout/HelmetMeta';
import { PermissionGroupsPage } from '../../../sections/@permission-groups-page/PermissionGroupsPage';
import { PermissionGroupsContextProvider } from '../../../sections/@permission-groups-page/context/PermissionGroupsContext';

export const PermissionGroupsPageWrapper = () => (
  <PermissionGroupsContextProvider>
    <HelmetMeta pageName="Grupos de permissão" />
    <PermissionGroupsPage />
  </PermissionGroupsContextProvider>
);
