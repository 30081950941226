import CheckIcon from '@mui/icons-material/Check';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import CloseIcon from '@mui/icons-material/Close';
import { Divider, Grid, InputLabel } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ControlledComboBox, SelectOption } from '../../../components/basics/ControlledComboBox';
import { ControlledTextInput } from '../../../components/basics/ControlledTextInput';
import { User } from '../../../models/User';
import { findSelectedOption } from '../../../utils/utils';
import { UsersFilterFields, useUsersPageContext } from '../context/UsersPageContext';
interface Props {
  onClose: () => void;
}

const filterStatusOptions: SelectOption[] = [
  { id: null, name: 'Todos' },
  { id: true, name: 'Ativo' },
  { id: false, name: 'Inativo' }
];

const getDefaultValues = (filter?: User): UsersFilterFields => {
  return {
    username: filter?.username ?? '',
    email: filter?.email ?? '',
    is_active: findSelectedOption(filterStatusOptions, filter?.is_active) ?? filterStatusOptions[0].id
  };
};

export const UsersFilterDialog = ({ onClose }: Props) => {
  const { filterFields, setFilterFields } = useUsersPageContext();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<UsersFilterFields>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: getDefaultValues(filterFields)
  });

  const onSubmit: SubmitHandler<UsersFilterFields> = async (data: UsersFilterFields) => {
    if (data.is_active?.id != null) {
      data.is_active = data.is_active.id;
    } else {
      delete data.is_active;
    }
    setFilterFields({ ...data } as UsersFilterFields);
    onClose();
  };

  const handleClear = () => {
    setFilterFields({} as UsersFilterFields);
    onClose();
  };

  return (
    <Dialog open={true} onClose={onClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        Filtros
      </DialogTitle>
      <Divider />
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="status">Nome Usuário</InputLabel>
              <ControlledTextInput
                name="username"
                placeholder="Nome Usuário"
                control={control}
                errorMessage={errors.username?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="status">E-mail</InputLabel>
              <ControlledTextInput placeholder="E-mail" name="email" control={control} errorMessage={errors.email?.message?.toString()} />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel id="status">Situação</InputLabel>
              <ControlledComboBox
                name="is_active"
                placeholder="Situação"
                control={control}
                errorMessage={errors.is_active?.message?.toString()}
                selectOptions={[
                  { id: null, name: 'Todos' },
                  { id: true, name: 'Ativo' },
                  { id: false, name: 'Inativo' }
                ]}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ mx: 2, my: 1 }}>
          <Button startIcon={<ClearAllIcon />} variant="outlined" onClick={handleClear}>
            Limpar
          </Button>
          <Button startIcon={<CloseIcon />} variant="outlined" onClick={onClose}>
            Fechar
          </Button>
          <Button startIcon={<CheckIcon />} variant="contained" type="submit" onClick={handleSubmit(onSubmit)}>
            Filtrar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
