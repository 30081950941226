// assets
import DisplaySettingsOutlinedIcon from '@mui/icons-material/DisplaySettingsOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { INavGroup } from '../models/Menu';
import { ROUTES } from '../routes/routes';

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

export const SettingsRoutes: INavGroup = {
  id: 'group-setting',
  title: 'Configurações',
  icon: <SettingsOutlinedIcon />,
  type: 'group',
  module: 'SETTINGS',
  level: 1,
  children: [
    {
      id: 'parameters',
      title: 'Parâmetros',
      type: 'item',
      url: ROUTES.authenticated.auxiliary.parameters,
      icon: <DisplaySettingsOutlinedIcon />,
      breadcrumbs: true,
      submodule: 'PARAMETER'
    }
  ]
};
