import { AxiosResponse, isAxiosError } from 'axios';
import createAxiosInstance from '../middlewares/axios.interceptors';
import { PermissionGroup } from '../models/PermissionGroup';
import { PermissionGroupsFilterFields } from '../sections/@permission-groups-page/context/PermissionGroupsContext';

const PermissionGroupsUrl = '/users/groups/';

export const PermissionGroupsService = {
  getAll: async () => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.get(PermissionGroupsUrl);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  get: async (filterFields: PermissionGroupsFilterFields, page: number = 0, page_size: number = 5) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.get(PermissionGroupsUrl, {
        params: {
          ...filterFields,
          page: page + 1,
          page_size: page_size
        }
      });
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  create: async (data: PermissionGroup) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.post(PermissionGroupsUrl, data);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  update: async (permissionGroupId: number, data: PermissionGroup) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.put(`${PermissionGroupsUrl}${permissionGroupId}/`, data);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  delete: async (id: number) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.delete(`${PermissionGroupsUrl}${id}/`);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  deleteMany: async (ids: string[]) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.post(`${PermissionGroupsUrl}batch-delete/`, {
        companies: ids
      });
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  }
};
