import React, { createContext, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ApiResults } from '../../../models/ApiResults';
import { RepresentativeLink } from '../../../models/RepresentativeLink';
import { RepresentativeLinkService } from '../../../services/RepresentativeLink.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { checkResponseStatus } from '../../../utils/api/response';

export interface RepresentativeLinkFilterFields extends RepresentativeLink {
  order_by?: string;
  page?: number;
  page_size?: number;
  search?: string;
  focco_representative_code?: string;
  focco_representative_description?: string;
  focco_representative_trade_name?: string;
  focco_representative_cnpj?: string;
  focco_representative_cpf?: string;
}

export interface RepresentativeLinkResult extends ApiResults {
  results: RepresentativeLink[];
}

interface RepresentativeLinkContextProps {
  representativeLinks: RepresentativeLinkResult | undefined;
  fetchRepresetativeLinks: () => void;
  deleteRepresentativeLinks: (id: number) => Promise<void>;
  deleteManyRepresentativeLinks: (items: string[]) => Promise<void>;
  filterFields: RepresentativeLinkFilterFields;
  setFilterFields: React.Dispatch<React.SetStateAction<RepresentativeLinkFilterFields>>;
  page: number;
  rowsPerPage: number;
  handleOrderChange: (property: string, order: 'asc' | 'desc') => void;
  handlePageChange: (page: number) => void;
  handleOnChangeRowsPerPage: (page_size: number) => void;
}

const RepresentativeLinksPageContext = createContext<RepresentativeLinkContextProps | undefined>(undefined);

interface Props {
  children: React.ReactNode;
}

export const LinkRepresentativePageContextProvider = ({ children }: Props) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(50);
  const [representativeLinks, setRepresentativeLinks] = useState<RepresentativeLinkResult | undefined>(undefined);
  const [filterFields, setFilterFields] = useState<RepresentativeLinkFilterFields>({} as RepresentativeLinkFilterFields);

  const fetchRepresetativeLinks = async () => {
    dispatch(showSpinner());

    try {
      const response = await RepresentativeLinkService.get(filterFields, page, rowsPerPage);
      if (checkResponseStatus(response)) {
        setRepresentativeLinks(response?.data);
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({ title: 'Erro', message: error?.data || 'Houve um erro ao processar a sua solicitação', severity: 'error' })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const deleteRepresentativeLinks = async (id: number) => {
    dispatch(showSpinner());
    try {
      await RepresentativeLinkService.delete(id);
      dispatch(
        showSnackbarAlert({
          title: 'Sucesso',
          message: 'Registro excluído com sucesso!',
          severity: 'success'
        })
      );
      fetchRepresetativeLinks();
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: error.data.detail || 'Houve um erro ao processar a sua solicitação',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const deleteManyRepresentativeLinks = async (items: string[]) => {
    dispatch(showSpinner());
    try {
      await RepresentativeLinkService.deleteMany(items);
      dispatch(
        showSnackbarAlert({
          title: 'Sucesso',
          message: 'Registros excluídos com sucesso!',
          severity: 'success'
        })
      );
      fetchRepresetativeLinks();
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: error.data.detail ?? 'Houve um erro ao processar a sua solicitação',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const handleOrderChange = (property: string, order: 'asc' | 'desc') => {
    const orderFilter = order === 'asc' ? property : `-${property}`;
    setFilterFields({ ...filterFields, order_by: orderFilter });
  };

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handleOnChangeRowsPerPage = (page_size: number) => {
    setRowsPerPage(page_size);
  };

  useEffect(() => {
    fetchRepresetativeLinks();
  }, [filterFields, page, rowsPerPage]);

  const contextValue: RepresentativeLinkContextProps = {
    representativeLinks,
    fetchRepresetativeLinks,
    deleteRepresentativeLinks,
    deleteManyRepresentativeLinks,
    filterFields,
    setFilterFields,
    page,
    rowsPerPage,
    handleOrderChange,
    handlePageChange,
    handleOnChangeRowsPerPage
  };

  return <RepresentativeLinksPageContext.Provider value={contextValue}>{children}</RepresentativeLinksPageContext.Provider>;
};

export const useRepresentativeLinksPageContext = () => {
  const context = useContext(RepresentativeLinksPageContext);
  if (!context) {
    throw new Error('useRepresentativeLinksPageContext must be used within a RepresentativeLinksPageContextProvider');
  }
  return context;
};
