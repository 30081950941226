import { MarketSegment } from './MarketSegment';
import { Representative } from './Representative';
import { SaleDivision } from './SaleDivision';

export enum SaleDivisionLinkType {
  CUSTOMER = 'Cliente',
  PROSPECT = 'Propsect'
}

export interface SaleDivisionLink {
  id: number;
  sale_division: SaleDivision;
  focco_representative: Representative;
  type: SaleDivisionLinkType | string;
  market_segment: MarketSegment;
  last_sync_date: string;
  funnel: string;
  funnel_stage: string;
  funnel_stage_name: string;
}
