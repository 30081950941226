import { AxiosResponse, isAxiosError } from 'axios';
import createAxiosInstance from '../middlewares/axios.interceptors';

const RDFunnelsUrl = '/rd-station/rd-funnels/';

export const RDFunnelsService = {
  getAll: async () => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.get(RDFunnelsUrl);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  }
};
