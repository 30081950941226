import AddIcon from '@mui/icons-material/Add';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Button, Tooltip } from '@mui/material';
import { debounce } from 'lodash';
import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useCrudPermissions } from '../../../hooks/useCrudPermissions';
import { SaleDivisionLink } from '../../../models/SaleDivisionLink';
import { SaleDivisionLinkDetailsDialog } from '../SaleDivisionLinkDetailsDialog';
import { SaleDivisionLinkFilterDialog } from '../SaleDivisionLinkFilterDialog';
import { SaleDivisionFormDialog } from '../SaleDivisionLinkFormDialog';
import { SaleDivisionLinkTable } from '../SaleDivisionLinkTable';
import { useSaleDivisionLinksPageContext } from '../context/SaleDivisionLinkPageContext';
import { ButtonsContainer } from './styles';

export const SaleDivisionLinkPage = () => {
  const { permissions } = useCrudPermissions({
    submodule: 'LINK_SALE_DIVISION'
  });

  const [showFilter, setShowFilter] = useState(false);
  const [newItem, setNewItem] = useState(false);
  const [editItem, setEditItem] = useState<SaleDivisionLink | undefined>(undefined);
  const [viewItem, setViewItem] = useState<SaleDivisionLink | undefined>(undefined);

  const { control } = useForm();
  const watchSearch = useWatch({
    control,
    name: 'search'
  });

  const { filterFields, setFilterFields } = useSaleDivisionLinksPageContext();

  const handleAddUser = () => {
    setNewItem(true);
  };

  const handleViewItem = (item: SaleDivisionLink) => {
    setViewItem(item);
  };

  const handleEditItem = (item: SaleDivisionLink) => {
    setEditItem(item);
  };

  const handleShowFilter = () => {
    setShowFilter(true);
  };

  const handleCloseDialogs = () => {
    setShowFilter(false);
    setNewItem(false);
    setEditItem(undefined);
    setViewItem(undefined);
  };

  useEffect(() => {
    if (watchSearch) {
      const debounced = debounce(() => {
        setFilterFields({
          ...filterFields,
          search: watchSearch
        });
      }, 500);
      debounced();
      return debounced.cancel;
    }
  }, [watchSearch]);

  return (
    <>
      <ButtonsContainer>
        {permissions.hasReadPermission && (
          <>
            <Tooltip title="Filtros">
              <Button variant="outlined" color="primary" startIcon={<FilterListIcon />} sx={{ mr: 1 }} onClick={handleShowFilter}>
                Filtros
              </Button>
            </Tooltip>
          </>
        )}
        {permissions.hasCreatePermission && (
          <Tooltip title="Criar Vínculo">
            <Button startIcon={<AddIcon />} variant="contained" onClick={handleAddUser}>
              Adicionar Vínculo
            </Button>
          </Tooltip>
        )}
      </ButtonsContainer>
      <SaleDivisionLinkTable onView={handleViewItem} onEdit={handleEditItem} permissions={permissions} />
      {showFilter && permissions.hasReadPermission && <SaleDivisionLinkFilterDialog onClose={handleCloseDialogs} />}
      {viewItem && <SaleDivisionLinkDetailsDialog item={viewItem} onClose={handleCloseDialogs} />}
      {(newItem || editItem) && permissions.hasCreatePermission && (
        <SaleDivisionFormDialog editItem={editItem} onClose={handleCloseDialogs} />
      )}
    </>
  );
};
