import CheckIcon from '@mui/icons-material/Check';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import CloseIcon from '@mui/icons-material/Close';
import { Divider, Grid, InputLabel } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ControlledComboBox, SelectOption } from '../../../components/basics/ControlledComboBox';
import { ControlledTextInput } from '../../../components/basics/ControlledTextInput';
import { findSelectedOption, formatDate, formatDateTimeToApi } from '../../../utils/utils';
import { IntegrationLogFilterFields, useIntegrationLogsPageContext } from '../context/IntegrationLogPageContext';
interface Props {
  onClose: () => void;
}

const filterOperationOptions: SelectOption[] = [
  { id: 'CREATE', name: 'Criação' },
  { id: 'UPDATE', name: 'Atualização' },
  { id: 'DELETE', name: 'Exclusão' }
];

const filterModelOptions: SelectOption[] = [
  { id: 'RepresentativeRelationship', name: 'Vínculo de Representante' },
  { id: 'SaleDivisionRelationship', name: 'Vínculo de Divisão de Venda' },
  { id: 'CustomerRelationship', name: 'Vínculo de Cliente' },
  { id: 'BudgetRelationship', name: 'Vínculo de Orçamento' }
];

const filterStatusOptions: SelectOption[] = [
  { id: 0, name: 'Sucesso' },
  { id: 1, name: 'Erro' }
];

const getDefaultValues = (filter?: IntegrationLogFilterFields): IntegrationLogFilterFields => {
  return {
    start_date: filter?.start_date ? formatDate(filter?.start_date) : '',
    end_date: filter?.end_date ? formatDate(filter?.end_date) : '',
    operation: findSelectedOption(filterOperationOptions, filter?.operation) ?? filterOperationOptions[0].id,
    model: findSelectedOption(filterModelOptions, filter?.model) ?? filterModelOptions[0].id,
    has_error: findSelectedOption(filterStatusOptions, filter?.has_error) ?? filterStatusOptions[0].id
  };
};
export const IntegrationLogFilterDialog = ({ onClose }: Props) => {
  const { filterFields, setFilterFields } = useIntegrationLogsPageContext();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<IntegrationLogFilterFields>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: getDefaultValues(filterFields)
  });

  const onSubmit: SubmitHandler<IntegrationLogFilterFields> = async (data: IntegrationLogFilterFields) => {
    if (data.operation?.id != null) {
      data.operation = data.operation.id;
    } else {
      delete data.operation;
    }
    if (data.model?.id != null) {
      data.model = data.model.id;
    } else {
      delete data.model;
    }
    if (data.has_error?.id != null) {
      data.has_error = data.has_error.id;
    } else {
      delete data.has_error;
    }

    data = {
      ...data,
      start_date: data?.start_date ? formatDateTimeToApi(data.start_date, true) : null,
      end_date: data?.end_date ? formatDateTimeToApi(data.end_date, false) : null
    };
    setFilterFields({ ...data } as IntegrationLogFilterFields);
    onClose();
  };

  const handleClear = () => {
    setFilterFields({} as IntegrationLogFilterFields);
    onClose();
  };

  return (
    <Dialog open={true} onClose={onClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        Filtros
      </DialogTitle>
      <Divider />
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="start_date">Data Inicial</InputLabel>
              <ControlledTextInput
                name="start_date"
                placeholder="Data Inicial"
                mask={'99/99/9999'}
                control={control}
                errorMessage={errors.start_date?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="end_date">Data Final</InputLabel>
              <ControlledTextInput
                name="end_date"
                placeholder="Data Final"
                mask={'99/99/9999'}
                control={control}
                errorMessage={errors.end_date?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="operation">Operação</InputLabel>
              <ControlledComboBox
                name="operation"
                placeholder="Operação"
                control={control}
                errorMessage={errors.operation?.message?.toString()}
                selectOptions={filterOperationOptions}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel id="model">Objeto</InputLabel>
              <ControlledComboBox
                name="model"
                placeholder="Objeto"
                control={control}
                errorMessage={errors.model?.message?.toString()}
                selectOptions={filterModelOptions}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="has_error">Status</InputLabel>
              <ControlledComboBox
                name="has_error"
                placeholder="Status"
                control={control}
                errorMessage={errors.has_error?.message?.toString()}
                selectOptions={filterStatusOptions}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ mx: 2, my: 1 }}>
          <Button startIcon={<ClearAllIcon />} variant="outlined" onClick={handleClear}>
            Limpar
          </Button>
          <Button startIcon={<CloseIcon />} variant="outlined" onClick={onClose}>
            Fechar
          </Button>
          <Button startIcon={<CheckIcon />} variant="contained" type="submit" onClick={handleSubmit(onSubmit)}>
            Filtrar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
