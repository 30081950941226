import { HelmetMeta } from '../../../layout/HelmetMeta';
import { CustomerLinkPage } from '../../../sections/@link-customer/CustomerLinkPage';
import { CustomerLinkPageContextProvider } from '../../../sections/@link-customer/context/CustomerLinkPageContext';

export const LinkCustomerPageWrapper = () => (
  <CustomerLinkPageContextProvider>
    <HelmetMeta pageName="Vinculo de Cliente" />
    <CustomerLinkPage />
  </CustomerLinkPageContextProvider>
);
