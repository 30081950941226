const config = {
  defaultPath: '/dashboard/default',
  fontFamily: `'Public Sans', sans-serif`,
  i18n: 'en',
  miniDrawer: false,
  container: true,
  mode: 'light',
  presetColor: 'default',
  themeDirection: 'ltr'
};

export default config;
export const drawerWidth = 260;
export const openDrawerWidth = 260;
export const closedDrawerWidth = 65;

export const logoPath = '/logo.png';
export const author = "Lidere Sistemas";
export const portalName = process.env.REACT_APP_PORTAL_NAME || `Portal ${author}`;