import CheckIcon from '@mui/icons-material/Check';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import CloseIcon from '@mui/icons-material/Close';
import { Divider, Grid, InputLabel } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ControlledTextInput } from '../../../components/basics/ControlledTextInput';
import { convertDateToISO, convertISOToDate } from '../../../utils/utils';
import { PaymentOrderFilterFields, usePaymentOrdersPageContext } from '../context/PaymentOrderPageContext';
interface Props {
  onClose: () => void;
}

const getDefaultValues = (filter?: PaymentOrderFilterFields): PaymentOrderFilterFields => {
  return {
    date: filter?.date ? convertISOToDate(filter.date) : ''
  };
};

export const PaymentOrderFilterDialog = ({ onClose }: Props) => {
  const { filterFields, setFilterFields } = usePaymentOrdersPageContext();

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors }
  } = useForm<PaymentOrderFilterFields>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: getDefaultValues(filterFields)
  });

  const onSubmit: SubmitHandler<PaymentOrderFilterFields> = async (data: PaymentOrderFilterFields) => {
    data = {
      ...data,
      date: data?.date ? convertDateToISO(data.date) : null
    };
    setFilterFields({ ...data } as PaymentOrderFilterFields);
    onClose();
  };

  const handleClear = () => {
    setFilterFields({} as PaymentOrderFilterFields);
    onClose();
  };

  return (
    <Dialog open={true} onClose={onClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        Filtros
      </DialogTitle>
      <Divider />
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="date">Data</InputLabel>
              <ControlledTextInput
                name="date"
                placeholder="Data"
                mask="99/99/9999"
                control={control}
                errorMessage={errors.date?.message?.toString()}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ mx: 2, my: 1 }}>
          <Button startIcon={<ClearAllIcon />} variant="outlined" onClick={handleClear}>
            Limpar
          </Button>
          <Button startIcon={<CloseIcon />} variant="outlined" onClick={onClose}>
            Fechar
          </Button>
          <Button startIcon={<CheckIcon />} variant="contained" type="submit" onClick={handleSubmit(onSubmit)}>
            Filtrar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
