import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Divider, Grid, InputLabel, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { ControlledCheckbox } from '../../../components/basics/ControlledCheckbox';
import { ControlledTextInput } from '../../../components/basics/ControlledTextInput';
import { Parameter } from '../../../models/CompanyParameters';
import { ParametersService } from '../../../services/CompanyParameters.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { setInputErrorsFromApi } from '../../../utils/utils';

interface Props {
  editItem?: Parameter;
  onClose: () => void;
  updateSaved: () => void;
}

export const CompanyParameterFormDialog = ({ editItem, onClose, updateSaved }: Props) => {
  const dispatch = useDispatch();

  const {
    control,
    handleSubmit,
    getValues,
    setError,
    formState: { errors }
  } = useForm<Parameter>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: editItem
      ? { ...editItem, value: editItem.encrypted ? '' : editItem.value }
      : {
          key: '',
          description: '',
          value: ''
        }
  });

  const updateCompanyParameters: SubmitHandler<Parameter> = async (data: Parameter) => {
    dispatch(showSpinner());
    try {
      const payload = {
        ...data
      } as Parameter;
      editItem?.id ? await ParametersService.update(data) : await ParametersService.create(payload);
      updateSaved();

      dispatch(
        showSnackbarAlert({
          title: 'Sucesso',
          message: 'Parametro atualizado com sucesso!',
          severity: 'success'
        })
      );
      onClose();
    } catch (error: any) {
      const formError = setInputErrorsFromApi(setError, error.data);
      if (formError) {
        dispatch(
          showSnackbarAlert({
            title: 'Erro',
            message: formError,
            severity: 'error'
          })
        );
      }
    } finally {
      dispatch(hideSpinner());
    }
  };

  const handleClose = () => {
    onClose();
  };

  const watchIsEncrypted = useWatch({
    control,
    name: 'encrypted',
    defaultValue: getValues('encrypted')
  });

  return (
    <Dialog open={true} onClose={handleClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        Editar Parâmetro
      </DialogTitle>
      <Divider />
      <form onSubmit={handleSubmit(updateCompanyParameters)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="trade_name">Descrição do Parâmetro</InputLabel>
              <ControlledTextInput
                placeholder="Descrição do Parâmetro"
                name="description"
                control={control}
                disabled={true}
                errorMessage={errors.description?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="key">Chave do Parâmetro</InputLabel>
              <ControlledTextInput
                name="key"
                placeholder="Chave do Parâmetro"
                control={control}
                disabled={true}
                errorMessage={errors.key?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="value">Valor</InputLabel>
              <ControlledTextInput name="value" placeholder="Valor" control={control} errorMessage={errors.value?.message?.toString()} />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body1">Criptografar?</Typography>
              <ControlledCheckbox name="encrypted" label={watchIsEncrypted ? 'Sim' : 'Não'} control={control} />
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ mx: 2, my: 1 }}>
          <Button startIcon={<CloseIcon />} variant="outlined" onClick={handleClose}>
            Cancelar
          </Button>
          <Button startIcon={<CheckIcon />} variant="contained" type="submit" onClick={handleSubmit(updateCompanyParameters)}>
            Salvar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
