import { AxiosResponse, isAxiosError } from 'axios';
import createAxiosInstance from '../middlewares/axios.interceptors';

export class BaseReadOnlyService {
  protected axiosInstance = createAxiosInstance();
  protected baseUrl: string = '';

  async list(filterFields?: object, page: number = 0, page_size: number = 5) {
    try {
      return await this.axiosInstance.get(this.baseUrl, {
        params: {
          ...filterFields,
          page: page + 1,
          page_size: page_size
        }
      });
    } catch (err) {
      this.handleError(err);
    }
  }

  async listAll(filterFields?: object) {
    try {
      return await this.list(filterFields, 0, 0);
    } catch (err) {
      this.handleError(err);
    }
  }

  async retrieve(id: number) {
    try {
      return await this.axiosInstance.get(`${this.baseUrl}${id}`);
    } catch (err) {
      this.handleError(err);
    }
  }

  protected handleError(err: unknown) {
    if (isAxiosError(err)) {
      throw err.response as AxiosResponse;
    } else {
      throw err;
    }
  }
}

export class BaseService<T> extends BaseReadOnlyService {

  async create(data: T) {
    try {
      return await this.axiosInstance.post(this.baseUrl, data);
    } catch (err) {
      this.handleError(err);
    }
  }

  async update(data: T, id: number) {
    try {
      return await this.axiosInstance.patch(`${this.baseUrl}${id}/`, data);
    } catch (err) {
      this.handleError(err);
    }
  }

  async delete(id: number) {
    try {
      return await this.axiosInstance.delete(`${this.baseUrl}${id}/`);
    } catch (err) {
      this.handleError(err);
    }
  }

  async deleteMany(ids: string[]) {
    try {
      return await this.axiosInstance.post(`${this.baseUrl}batch-delete/`, { users: ids });
    } catch (err) {
      this.handleError(err);
    }
  }
}
