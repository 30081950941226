import { yupResolver } from '@hookform/resolvers/yup';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { ControlledTextInput } from '../../../../../components/basics/ControlledTextInput';
import { AuthService } from '../../../../../services/Auth.service';
import { hideSpinner, showSpinner } from '../../../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../../../store/slicers/snackbarAlert.slicer';
import { UserPasswordEditSchema } from '../../../../../utils/forms/validations/formValidations';

type Props = {
  showModal: boolean,
  hideModal: () => void
}

export const EditPasswordModal = ({ showModal, hideModal }: Props) => {
  const dispatch = useDispatch();

  const { control, reset, handleSubmit, getValues, setError, formState: { errors, isDirty, isValid } } = useForm<any>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(UserPasswordEditSchema),
  });

  const onSubmit = async (data: any) => {
    dispatch(showSpinner());
    const submitData = {
      current_password: data.current_password,
      confirm_password: data.confirm_password,
      password: data.password,
    };
    try {
      await AuthService.editPassword(submitData);
      dispatch(
        showSnackbarAlert({
          title: 'Sucesso',
          message: 'Senha atualizada com sucesso!',
          severity: 'success'
        })
      );
      reset({});
      hideModal();
    } catch (error: any) {
      const fields = Object.keys(getValues());
      let nonFieldErrors: string[] = [];
      Object.keys(error?.data).forEach((key) => {
        if (fields.includes(key)) {
          setError(key, { message: error?.data[key] })
        } else {
          nonFieldErrors.push(error?.data[key])
        }
      });
      if (nonFieldErrors.length > 0) {
        dispatch(
          showSnackbarAlert({
            title: 'Erro',
            message: nonFieldErrors.join('\n'),
            severity: 'error'
          })
        );
      }
    } finally {
      dispatch(hideSpinner());
    }
  }
  return (
    <Dialog open={showModal} onClose={hideModal}>
      <DialogTitle>Senha</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container spacing={4}>
            <Grid item xs={12} md={12}>
              <ControlledTextInput
                control={control}
                name="current_password"
                label="Senha Atual"
                type="password"
                errorMessage={errors.current_password?.message?.toString()}
                variant="standard"
                helperText="Para alterar sua senha, é necessário informar a senha que você está utilizando atualmente."
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <ControlledTextInput
                control={control}
                name="password"
                label="Nova Senha"
                type="password"
                errorMessage={errors.password?.message?.toString()}
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <ControlledTextInput
                control={control}
                name="confirm_password"
                label="Confirmação de Senha"
                type="password"
                errorMessage={errors.confirm_password?.message?.toString()}
                variant="standard"
              />
            </Grid>
          </Grid>
          <DialogActions>
            <Button startIcon={<CloseIcon />} variant="outlined" onClick={hideModal}>
              Cancelar
            </Button>
            <Button startIcon={<CheckIcon />} variant="contained" type="submit" disabled={!isDirty || !isValid}>
              Salvar
            </Button>
          </DialogActions>
        </DialogContent>
      </form>
    </Dialog >
  );
};
