import { AppBar, AppBarProps } from '@mui/material';
import styled from 'styled-components';
import { closedDrawerWidth, openDrawerWidth } from '../../../config';

interface AppBarStyled extends AppBarProps {
  open: boolean;
  ismobile: boolean;
}

export const AppBarStyled = styled(AppBar)<AppBarStyled>(({ open, ismobile, theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  width: ismobile ? '100%' : `calc(100% - ${closedDrawerWidth}px)`,
  ...(open && {
    marginLeft: openDrawerWidth,
    width: `calc(100% - ${openDrawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}));
