import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, DialogTitle } from '@mui/material';

interface Props {
  title: string;
  onClose: () => void;
}

export const DialogHeader = ({ title, onClose }: Props) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: 1, mr: 2 }}>
      <DialogTitle variant="h4" color="primary">
        Detalhes do grupo de permissões
      </DialogTitle>
      <Button variant="text" color="primary" onClick={onClose} startIcon={<CloseIcon />}>
        Fechar
      </Button>
    </Box>
  );
};
