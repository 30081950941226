import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

// material-ui
import { Box, Toolbar, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// project import
import Breadcrumbs from '../../components/@extended/Breadcrumbs';
import { MenuItems } from '../../menu-items';

// types
import { openDrawer } from '../../store/slicers/menu.slicers';
import { RootState } from '../../store/store';
import { MainDrawer } from './Drawer';
import { Header } from './Header';

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme();
  const matchDownLG = useMediaQuery(theme.breakpoints.down('lg'));
  const dispatch = useDispatch();

  const { drawerOpen } = useSelector((state: RootState) => state.menu);

  const handleDrawerToggle = () => {
    dispatch(openDrawer({ drawerOpen: !drawerOpen }));
  };

  // set media wise responsive drawer
  useEffect(() => {
    if (matchDownLG) {
      dispatch(openDrawer({ drawerOpen: false }));
    }
  }, [matchDownLG]);

  return (
    //LAYOUT PAGINA
    <Box sx={{ display: 'flex', flex: 1, minWidth: '100%', minHeight: '100vh', backgroundColor: theme.palette.grey[200] }}>
      <Header open={drawerOpen} handleDrawerToggle={handleDrawerToggle} />
      <MainDrawer open={drawerOpen} handleDrawerToggle={handleDrawerToggle} />
      <Box component="main" sx={{ width: '100%', flexGrow: 1, p: { xs: 2, sm: 3 } }}>
        <Toolbar />
        <Breadcrumbs navigation={MenuItems} title />
        <Outlet />
      </Box>
      {/* {matchDownSM && <BottomNavigator />} */}
    </Box>
  );
};

export default MainLayout;
