import { AxiosResponse, isAxiosError } from 'axios';
import createAxiosInstance from '../middlewares/axios.interceptors';
import { SaleDivisionLink } from '../models/SaleDivisionLink';
import { SaleDivisionLinkFilterFields } from '../sections/@link-sales-division/context/SaleDivisionLinkPageContext';

const SaleDivisionLinkUrl = '/rd-station/sales-division-relationship/';

export const SaleDivisionLinkService = {
  getAll: async () => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.get(SaleDivisionLinkUrl);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  get: async (filterFields: SaleDivisionLinkFilterFields, page: number = 0, page_size: number = 5) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.get(SaleDivisionLinkUrl, {
        params: {
          ...filterFields,
          page: page + 1,
          page_size: page_size
        }
      });
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  create: async (data: SaleDivisionLink) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.post(SaleDivisionLinkUrl, data);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  update: async (data: SaleDivisionLink) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.put(`${SaleDivisionLinkUrl}${data.id}/`, data);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  delete: async (id: number) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.delete(`${SaleDivisionLinkUrl}${id}/`);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  deleteMany: async (ids: string[]) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.post(`${SaleDivisionLinkUrl}batch-delete/`, {
        companies: ids
      });
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  }
};
