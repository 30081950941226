import CloseIcon from '@mui/icons-material/Close';
import { Divider, Grid, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Availability } from '../../../models/Availability';
import { AvailabilityMonthValue } from '../../../models/AvailabilityMonthValue';
import { AvailabilityValue } from '../../../models/AvailabilityValue';
import { AvailabilityService } from '../../../services/Availability.service';
import { AvailabilityMonthValueFilterFields, AvailabilityMonthValueService } from '../../../services/AvailabilityMonthValue.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { checkResponseStatus } from '../../../utils/api/response';
import { formatCurrency, formatDate } from '../../../utils/utils';

interface Props {
  item: AvailabilityValue;
  onClose: () => void;
}

export const AvailabilityValueDetailsDialog = ({ item, onClose }: Props) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState<boolean>(true);
  const [availabilities, setAvailabilities] = useState<Availability[] | undefined>(undefined);
  const [availabilityMonthValues, setAvailabilityMonthValues] = useState<AvailabilityMonthValue[] | undefined>(undefined);

  const fetchAvailabilities = async () => {
    try {
      const response = await AvailabilityService.get();
      if (checkResponseStatus(response)) {
        const result = response?.data?.results;
        setAvailabilities(result);
      }
    } catch (error: any) {
      dispatch(showSnackbarAlert({ title: 'Erro', message: error?.data || 'Houve um erro ao buscar Disponibilidades', severity: 'error' }));
    }
  };

  const fetchAvailabilityMonthValues = async () => {
    try {
      const response = await AvailabilityMonthValueService.get({ availability_month: item?.id } as AvailabilityMonthValueFilterFields);
      if (checkResponseStatus(response)) {
        const result = response?.data?.results;
        setAvailabilityMonthValues(result);
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: error?.data || 'Houve um erro ao buscar Saldos das Disponibilidades',
          severity: 'error'
        })
      );
    }
  };

  useEffect(() => {
    const loadData = async () => {
      dispatch(showSpinner());
      setLoading(true);

      await fetchAvailabilities();
      if (item?.id) {
        await fetchAvailabilityMonthValues();
      }
      setLoading(false);
      dispatch(hideSpinner());
    };

    loadData();
  }, []);

  return (
    <Dialog open={!loading} onClose={onClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        Detalhes do Saldo de Disponibilidade
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1" color="secondary" gutterBottom>
              Data:
            </Typography>
            <Typography variant="body1" gutterBottom>
              {formatDate(item.date)}
            </Typography>
          </Grid>
          {availabilityMonthValues?.map((availabilityValue, index) => (
            <Grid container item xs={12} spacing={1} key={index}>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" color="secondary">
                  {availabilities?.find((avail) => avail.id === availabilityValue.availability)?.description}:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">{formatCurrency(availabilityValue.value)}</Typography>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ mx: 2, my: 1 }}>
        <Button startIcon={<CloseIcon />} variant="outlined" onClick={onClose}>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
