import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import { ChangeEvent, useEffect, useState } from 'react';
import { IconButtonHover } from '../../../components/basics/IconButtonHover';
import { StyledTableRow } from '../../../components/basics/StyledTableRow';
import { HeadCell, Order, TableHeaderComponent } from '../../../components/basics/TableHeaderComponent';
import { PermissionsObj } from '../../../hooks/useCrudPermissions';
import { IntegrationLog, OperationEnum, getModelDisplay } from '../../../models/IntegrationLog';
import { formatDateTime, labelDisplayedRows } from '../../../utils/utils';
import { useIntegrationLogsPageContext } from '../context/IntegrationLogPageContext';

const headCells: readonly HeadCell[] = [
  {
    id: 'operation_time',
    align: 'left',
    disablePadding: false,
    label: 'Data',
    disableSorting: false
  },
  {
    id: 'operation',
    align: 'left',
    disablePadding: true,
    label: 'Operação',
    disableSorting: false
  },
  {
    id: 'model',
    align: 'left',
    disablePadding: true,
    label: 'Objeto',
    disableSorting: false
  },
  {
    id: 'status',
    align: 'left',
    disablePadding: false,
    label: 'Status',
    disableSorting: true
  },
  {
    id: 'actions',
    align: 'right',
    disablePadding: false,
    label: 'Ações',
    disableSorting: true
  }
];

interface Props {
  onView: (item: IntegrationLog) => void;
  permissions: PermissionsObj;
}

export const IntegrationLogTable = ({ onView, permissions }: Props) => {
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<string>('username');
  const [selected, setSelected] = useState<string[]>([]);

  const {
    integrationLogs,
    page,
    rowsPerPage,
    setFilterFields,
    filterFields,
    handleOrderChange,
    handlePageChange,
    handleOnChangeRowsPerPage
  } = useIntegrationLogsPageContext();

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(String(property));
    handleOrderChange(property, isAsc ? 'desc' : 'asc');
  };

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = integrationLogs?.results.map((item: IntegrationLog) => String(item.id)) || [];
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    handlePageChange(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    handleOnChangeRowsPerPage(parseInt(event.target.value, 10));
  };

  useEffect(() => {
    const currentDate = new Date();
    const start_date_filter = new Date(currentDate.setDate(currentDate.getDate() - 15));
    setFilterFields({ ...filterFields, start_date: start_date_filter });
  }, []);

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'small'}>
            <TableHeaderComponent
              headCells={headCells}
              numSelected={selected?.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={integrationLogs?.count || 0}
              hideCheckbox={true}
            />
            <TableBody>
              {integrationLogs && integrationLogs?.count > 0 ? (
                <>
                  {integrationLogs?.results?.map((row: IntegrationLog, index: number) => {
                    return (
                      <StyledTableRow role="checkbox" tabIndex={-1} key={row.id} sx={{ cursor: 'pointer' }}>
                        <TableCell align="left">{formatDateTime(row.operation_time)}</TableCell>
                        <TableCell align="left">{OperationEnum[row.operation] ? OperationEnum[row.operation] : '-'}</TableCell>
                        <TableCell align="left">{getModelDisplay(row.model)}</TableCell>
                        <TableCell align="left">{row.error_traceback ? 'Erro' : 'Sucesso'}</TableCell>
                        <TableCell align="right">
                          {permissions.hasReadPermission && <IconButtonHover row={row} onClick={onView} type="view" />}
                        </TableCell>
                      </StyledTableRow>
                    );
                  })}
                </>
              ) : (
                <StyledTableRow>
                  <TableCell align="center" colSpan={6}>
                    Nenhum registro encontrado
                  </TableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {integrationLogs && integrationLogs?.count > 0 && (
          <TablePagination
            rowsPerPageOptions={[5, 25, 50]}
            component="div"
            count={integrationLogs?.count || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Linhas por página"
            labelDisplayedRows={labelDisplayedRows}
          />
        )}
      </Paper>
    </Box>
  );
};
