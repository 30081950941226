import { HelmetMeta } from '../../../layout/HelmetMeta';
import { PaymentOrderPage } from '../../../sections/@payment-order/PaymentOrderPage';
import { PaymentOrderPageContextProvider } from '../../../sections/@payment-order/context/PaymentOrderPageContext';

export const PaymentOrderPageWrapper = () => (
  <PaymentOrderPageContextProvider>
    <HelmetMeta pageName="Ordem de Pagamento" />
    <PaymentOrderPage />
  </PaymentOrderPageContextProvider>
);
