import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BaseEcharts } from '../../../components/charts/BaseEcharts';
import { ChannelAvgTicketWithConsolidatedQuantityService } from '../../../services/Reports.service';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { getVerboseNameFromDate, toTitleCase } from '../../../utils/utils';
import { formatValue, formatValueToBRL } from '../utils';

type Props = {
  channel: string;
};

export const ChannelAvgTicketWithConsolidatedQuantity = ({ channel }: Props) => {
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();

  const options = {
    tooltip: {
      formatter: function (params: any) {
        const obj = data.find((_obj) => toTitleCase(getVerboseNameFromDate(_obj.month)) == params.name);
        return `${params.marker} ${params.name}<br/>\ 
        Ticket Médio: <b>${formatValueToBRL(obj.average_ticket)}</b><br/>\
        Quantidade de Equipamentos Faturados: <b>${formatValue(obj.revenue_equipment)}</b>`;
      }
    },
    xAxis: {
      type: 'value',
      axisLabel: {
        show: false
      }
    },
    yAxis: {
      type: 'category',
      data: data.map((item) => toTitleCase(getVerboseNameFromDate(item.month)))
    },
    series: [
      {
        data: data.map((item) => item.average_ticket),
        type: 'bar',
        itemStyle: {
          color: '#91cc75'
        },
        label: {
          show: true,
          position: 'right',
          fontSize: 10,
          formatter: (params: any) => formatValue(params.value)
        }
      },
      {
        data: data.map((item) => item.revenue_equipment),
        type: 'line',
        label: {
          show: true,
          position: 'right',
          textBorderColor: 'transparent',
          fontSize: 10,
          formatter: (params: any) => formatValue(params.value)
        }
      }
    ]
  };

  const fetchReport = async () => {
    setLoading(true);
    try {
      const response = await new ChannelAvgTicketWithConsolidatedQuantityService().listAll({ sales_channel: channel });
      if (response?.data?.results) {
        setData(response.data.results.report);
      }
    } catch (error: any) {
      const errorMessage = error?.data?.detail || 'Erro ao buscar dados do Gráfico';
      dispatch(
        showSnackbarAlert({
          title: 'Erro!',
          message: errorMessage,
          severity: 'error'
        })
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchReport();
  }, []);

  return (
    <BaseEcharts
      title={`Quantidade de Equipamento Fat + Ticket Médio - ${channel}`}
      option={options}
      dataLength={data.length}
      loading={loading}
    />
  );
};
