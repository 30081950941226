import { yupResolver } from '@hookform/resolvers/yup';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Alert, Divider, Grid, InputLabel } from '@mui/material'; // Import Tab components
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { ControlledTextInput } from '../../../components/basics/ControlledTextInput';
import { FormMessage } from '../../../models/FormMessage';
import { User } from '../../../models/User';
import { UsersService } from '../../../services/Users.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { UserSchema } from '../../../utils/forms/validations/formValidations';
import { setInputErrorsFromApi } from '../../../utils/utils';
import { useUsersPageContext } from '../context/UsersPageContext';

interface Props {
  editItem?: User;
  onClose: () => void;
}

export const UsersFormDialog = ({ editItem, onClose }: Props) => {
  const dispatch = useDispatch();
  const [formMessage, setFormMessage] = useState<FormMessage | null>(null);

  const { fetchUsers } = useUsersPageContext();

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm<User>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(UserSchema),
    defaultValues: editItem ?? {
      username: '',
      email: '',
      password: '',
      confirm_password: '',
      is_active: true,
      is_staff: true
    }
  });

  const onSubmitForm: SubmitHandler<User> = async (data: User) => {
    if (!editItem?.id && !data.password) {
      setError('password', {
        type: 'manual',
        message: 'A senha é obrigatória para um novo usuário'
      });
      return;
    }

    if (data.password && data.password !== data.confirm_password) {
      setError('confirm_password', {
        type: 'manual',
        message: 'As senhas não conferem'
      });
      return;
    }

    if (!data.password && !data.confirm_password) {
      delete data.password;
      delete data.confirm_password;
    }

    //se o usuário não tem ID, ele é novo, então é necessário setar o is_staff como true
    if (!editItem?.id) {
      data.is_staff = true;
    } else {
      data.is_staff = editItem.is_staff;
    }
    delete data.profiles;
    delete data.permissions;

    dispatch(showSpinner());
    try {
      const response = editItem?.id ? await UsersService.update(data) : await UsersService.create(data);
      if (response) {
        dispatch(
          showSnackbarAlert({
            title: 'Sucesso!',
            message: editItem?.id ? 'Usuário atualizado com sucesso!' : 'Usuário criado com sucesso!',
            severity: 'success'
          })
        );
      }
      fetchUsers();
      onClose();
    } catch (error: any) {
      setFormMessage({
        severity: 'error',
        message: setInputErrorsFromApi(setError, error.data) || ''
      });
    } finally {
      dispatch(hideSpinner());
    }
  };

  return (
    <Dialog open={true} onClose={onClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        {editItem?.id ? 'Editar' : 'Novo'} Usuário
      </DialogTitle>
      <Divider />
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {formMessage?.message && (
                <Grid item xs={12}>
                  <Alert severity={formMessage?.severity} variant="outlined" sx={{ width: '100%' }}>
                    {formMessage.message}
                  </Alert>
                </Grid>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor="status">Usuário</InputLabel>
              <ControlledTextInput
                name="username"
                placeholder="Usuário"
                control={control}
                errorMessage={errors.username?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor="status">E-mail</InputLabel>
              <ControlledTextInput
                placeholder="E-mail"
                name="email"
                type="email"
                control={control}
                errorMessage={errors.email?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor="status">Nova Senha</InputLabel>
              <ControlledTextInput
                placeholder="Nova Senha"
                name="password"
                type="password"
                control={control}
                errorMessage={errors.password?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor="status">Confirmar Senha</InputLabel>
              <ControlledTextInput
                placeholder="Confirmar Senha"
                name="confirm_password"
                type="password"
                control={control}
                errorMessage={errors.confirm_password?.message?.toString()}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ mx: 2, my: 1 }}>
          <Button startIcon={<CloseIcon />} variant="outlined" onClick={onClose}>
            Cancelar
          </Button>
          <Button startIcon={<CheckIcon />} variant="contained" type="submit" onClick={handleSubmit(onSubmitForm)}>
            Salvar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
