import AddIcon from '@mui/icons-material/Add';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Box, Button, Tooltip } from '@mui/material';
import { debounce } from 'lodash';
import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { ControlledTextInput } from '../../../components/basics/ControlledTextInput';
import { useCrudPermissions } from '../../../hooks/useCrudPermissions';
import { User } from '../../../models/User';
import { UsersDetailsDialog } from '../UsersDetailsDialog';
import { UsersFilterDialog } from '../UsersFilterDialog';
import { UsersFormDialog } from '../UsersFormDialog';
import { UsersPermissionDialog } from '../UsersPermissionDialog';
import { UsersTable } from '../UsersTable';
import { useUsersPageContext } from '../context/UsersPageContext';
import { ButtonsContainer } from './styles';

export const UsersPage = () => {
  const { permissions } = useCrudPermissions({
    submodule: 'USER'
  });

  const [showFilter, setShowFilter] = useState(false);
  const [newItem, setNewItem] = useState(false);
  const [editItem, setEditItem] = useState<User | undefined>(undefined);
  const [editPermission, setEditPermission] = useState<User | undefined>(undefined);
  const [viewItem, setViewItem] = useState<User | undefined>(undefined);

  const { control } = useForm();
  const watchSearch = useWatch({
    control,
    name: 'search'
  });

  const { filterFields, setFilterFields } = useUsersPageContext();

  const handleAddUser = () => {
    setNewItem(true);
  };

  const handleViewItem = (item: User) => {
    setViewItem(item);
  };

  const handleEditItem = (item: User) => {
    setEditItem(item);
  };

  const handleEditPermission = (item: User) => {
    setEditPermission(item);
  };

  const handleShowFilter = () => {
    setShowFilter(true);
  };

  const handleCloseDialogs = () => {
    setShowFilter(false);
    setNewItem(false);
    setEditItem(undefined);
    setViewItem(undefined);
    setEditPermission(undefined);
  };

  useEffect(() => {
    if (watchSearch) {
      const debounced = debounce(() => {
        setFilterFields({
          ...filterFields,
          search: watchSearch
        });
      }, 500);
      debounced();
      return debounced.cancel;
    }
  }, [watchSearch]);

  return (
    <>
      <ButtonsContainer>
        {permissions.hasReadPermission && (
          <>
            <Box mr={1}>
              <ControlledTextInput
                control={control}
                name="search"
                label="Busca de Usuários"
                placeholder="Digite para Pesquisar"
                sx={{ mr: 1 }}
              />
            </Box>
            <Tooltip title="Filtros">
              <Button variant="outlined" color="primary" startIcon={<FilterListIcon />} sx={{ mr: 1 }} onClick={handleShowFilter}>
                Filtros
              </Button>
            </Tooltip>
          </>
        )}
        {permissions.hasCreatePermission && (
          <Tooltip title="Criar Usuário">
            <Button startIcon={<AddIcon />} variant="contained" onClick={handleAddUser}>
              Adicionar
            </Button>
          </Tooltip>
        )}
      </ButtonsContainer>
      <UsersTable onView={handleViewItem} onEdit={handleEditItem} onEditPermission={handleEditPermission} permissions={permissions} />
      {showFilter && permissions.hasReadPermission && <UsersFilterDialog onClose={handleCloseDialogs} />}
      {viewItem && <UsersDetailsDialog item={viewItem} onClose={handleCloseDialogs} />}
      {(newItem || editItem) && permissions.hasCreatePermission && <UsersFormDialog editItem={editItem} onClose={handleCloseDialogs} />}
      {editPermission && permissions.hasUpdatePermission && (
        <UsersPermissionDialog editItem={editPermission} onClose={handleCloseDialogs} />
      )}
    </>
  );
};
