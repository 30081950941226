import { useMemo } from 'react';

// material-ui
import { Drawer, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// project import
import { openDrawerWidth } from '../../../config';
import { DrawerContent } from './DrawerContent';
import { DrawerHeader } from './DrawerHeader';
import { MiniDrawerStyled } from './MiniDrawerStyled';

// ==============================|| MAIN LAYOUT - DRAWER ||============================== //

interface MainDrawerProps {
  open: boolean;
  handleDrawerToggle: () => void;
  window?: () => Window;
}

export const MainDrawer = ({ open, handleDrawerToggle, window }: MainDrawerProps) => {
  const theme = useTheme();
  const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));

  // responsive drawer container
  const container = window !== undefined ? () => window().document.body : undefined;

  // header content
  const drawerHeader = useMemo(() => <DrawerHeader open={open} />, [open]);
  const drawerContent = useMemo(() => <DrawerContent />, []);

  return (
    <>
      {!matchDownMD ? (
        <MiniDrawerStyled variant="permanent" open={open}>
          {drawerHeader}
          {drawerContent}
        </MiniDrawerStyled>
      ) : (
        <Drawer
          container={container}
          variant="temporary"
          open={open}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
          sx={{
            display: { xs: 'block', lg: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: openDrawerWidth,
              boxShadow: 'inherit'
            }
          }}
        >
          {open && drawerHeader}
          {open && drawerContent}
        </Drawer>
      )}
    </>
  );
};
