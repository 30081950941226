import { yupResolver } from '@hookform/resolvers/yup';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Alert, Divider, Grid, InputLabel } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { AutocompleteSearch } from '../../../components/basics/AutocompleteSearch';
import { SelectOption } from '../../../components/basics/ControlledComboBox';
import { Customer } from '../../../models/Customer';
import { CustomerLink } from '../../../models/CustomerLink';
import { FormMessage } from '../../../models/FormMessage';
import { RDUser } from '../../../models/RDUser';
import { CustomerService } from '../../../services/Customer.service';
import { CustomerLinkService } from '../../../services/CustomerLink.service';
import { RDCompanyService } from '../../../services/RDCompany.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { CustomerLinkSchema } from '../../../utils/forms/validations/formValidations';
import { setInputErrorsFromApi } from '../../../utils/utils';
import { useCustomerLinksPageContext } from '../context/CustomerLinkPageContext';

interface Props {
  editItem?: CustomerLink;
  onClose: () => void;
}

export const CustomerFormDialog = ({ editItem, onClose }: Props) => {
  const dispatch = useDispatch();
  const [formMessage, setFormMessage] = useState<FormMessage | null>(null);
  const [rdCompanies, setRDCompanies] = useState<SelectOption[]>([]);
  const [customer, setCustomer] = useState<SelectOption[]>([]);
  const { fetchCustomerLinks } = useCustomerLinksPageContext();
  const [selectedCustomer, setSelectedCustomer] = useState<SelectOption>({} as SelectOption);
  const [selectedCompany, setSelectedCompany] = useState<SelectOption>({} as SelectOption);

  const {
    control,
    handleSubmit,
    setError,
    setValue,
    formState: { errors }
  } = useForm<CustomerLink>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(CustomerLinkSchema),
    defaultValues: editItem
      ? {
          ...editItem,
          focco_customer_detail: {
            id: editItem.focco_customer_detail.id,
            name: editItem.focco_customer_detail.trade_name,
            establishment: editItem.focco_customer_detail?.establishment,
            row_version: editItem.focco_customer_detail?.row_version
          } as SelectOption
        }
      : {}
  });
  const onSubmitForm: SubmitHandler<CustomerLink> = async (data: CustomerLink) => {
    data = {
      ...data,
      focco_customer: selectedCustomer?.id,
      establishment: selectedCustomer?.establishment,
      row_version: selectedCustomer?.row_version,
      rd_company: selectedCompany?.id,
      rd_company_name: selectedCompany?.name
    };
    dispatch(showSpinner());
    try {
      const response = editItem?.id ? await CustomerLinkService.update(data) : await CustomerLinkService.create(data);
      if (response) {
        dispatch(
          showSnackbarAlert({
            title: 'Sucesso!',
            message: editItem?.id ? 'Vínculo atualizado com sucesso!' : 'Vínculo criado com sucesso!',
            severity: 'success'
          })
        );
      }
      fetchCustomerLinks();
      onClose();
    } catch (error: any) {
      setFormMessage({
        severity: 'error',
        message: setInputErrorsFromApi(setError, error.data) || ''
      });
    } finally {
      dispatch(hideSpinner());
    }
  };

  const handleSelectCompany = (value: SelectOption) => {
    setSelectedCompany(value);
  };
  const handleSelectCustomer = (value: SelectOption) => {
    setSelectedCustomer(value);
  };
  const fetchRDCompanies = async (watchRCompanySearch?: string) => {
    try {
      const response = await RDCompanyService.get({ search: watchRCompanySearch });
      if (response?.data?.results) {
        const dataRDCompanies = response?.data?.results;
        setRDCompanies(
          dataRDCompanies.map((company: RDUser) => ({
            id: company.id,
            name: company.name
          }))
        );
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: 'Erro ao buscar Empresas do RD Station',
          severity: 'error'
        })
      );
    }
  };

  const fetchCustomer = async (watchCustomerSearch?: string) => {
    try {
      const response = await CustomerService.get({ search: watchCustomerSearch });
      if (response?.data?.results) {
        const dataCustomer = response?.data?.results;
        setCustomer(
          dataCustomer.map((customer: Customer) => ({
            id: customer.id,
            name: customer.trade_name,
            establishment: customer.establishment,
            row_version: customer.row_version
          }))
        );
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: 'Erro ao buscar Clientes Focco',
          severity: 'error'
        })
      );
    }
  };

  useEffect(() => {
    if (editItem?.focco_customer_detail) {
      const customer = editItem.focco_customer_detail;
      setSelectedCustomer({
        id: customer.id,
        name: customer.name,
        establishment: customer.establishment,
        row_version: customer.row_version
      });
      setCustomer([{ id: customer.id, name: customer.name, establishment: customer.establishment, row_version: customer.row_version }]);
    }
    if (editItem?.rd_company) {
      const company = { id: editItem.rd_company, name: editItem.rd_company_name };
      setSelectedCompany({
        id: company.id,
        name: company.name
      });
      setRDCompanies([{ id: company.id, name: company.name }]);
    }
  }, [editItem]);

  return (
    <Dialog open={true} onClose={onClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        {editItem?.id ? 'Editar' : 'Novo'} Vínculo de Cliente
      </DialogTitle>
      <Divider />
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {formMessage?.message && (
                <Grid item xs={12}>
                  <Alert severity={formMessage?.severity} variant="outlined" sx={{ width: '100%' }}>
                    {formMessage.message}
                  </Alert>
                </Grid>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="focco_customer_detail">Clientes</InputLabel>
              <AutocompleteSearch
                value={selectedCustomer}
                defaultValue={
                  editItem?.focco_customer_detail.id
                    ? {
                        id: editItem.focco_customer_detail.id,
                        name: editItem.focco_customer_detail.name
                      }
                    : undefined
                }
                onSearch={fetchCustomer}
                selectOptions={customer}
                onSelect={(event, value: SelectOption) => handleSelectCustomer(value)}
                errorMessage={errors.focco_customer_detail?.message}
                getOptionLabel={(option) => option.name}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="rd_company">Empresas</InputLabel>
              <AutocompleteSearch
                value={selectedCompany}
                defaultValue={
                  editItem?.rd_user
                    ? {
                        id: editItem.rd_user,
                        name: editItem.rd_user_name
                      }
                    : undefined
                }
                onSearch={fetchRDCompanies}
                selectOptions={rdCompanies}
                onSelect={(event, value: SelectOption) => handleSelectCompany(value)}
                errorMessage={errors.rd_company?.message}
                getOptionLabel={(option) => option.name}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ mx: 2, my: 1 }}>
          <Button startIcon={<CloseIcon />} variant="outlined" onClick={onClose}>
            Cancelar
          </Button>
          <Button startIcon={<CheckIcon />} variant="contained" type="submit" onClick={handleSubmit(onSubmitForm)}>
            Salvar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
