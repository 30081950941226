import { HelmetMeta } from '../../../layout/HelmetMeta';
import { CompanyParametersPage } from '../../../sections/@company-parameters/CompanyParametersPage';
import { CompanyParametersPageContext } from '../../../sections/@company-parameters/context/CompanyParametersPageContext';

export const CompanyParametersPageWrapper = () => (
  <CompanyParametersPageContext>
    <HelmetMeta pageName="Parâmetros" />
    <CompanyParametersPage />
  </CompanyParametersPageContext>
);
