import { Grid } from '@mui/material';
import { RevenueByMonth } from './RevenueByMonth';
import { RevenueByChannel } from './RevenueByChannel';
import { NetRevenueByChannel } from './NetRevenueByChannel';
import { RevenueEquipmentByMonth } from './RevenueEquipmentByMonth';
import { EquipmentRepresentationByChannel } from './EquipmentRepresentationByChannel';
import { NetRevenueByChannelAndMonth } from './NetRevenueByChannelAndMonth';
import { EquipmentRepresentationByValue } from './EquipmentRepresentationByValue';
import { AvgTicketWithConsolidatedQuantity } from './AvgTicketWithConsolidatedQuantity';
import { ChannelAvgTicketWithConsolidatedQuantity } from './ChannelAvgTicketWithConsolidatedQuantity';
import { MarkupByMonth } from './MarkupByMonth';
import { MarkupByChannelMonth } from './MarkupByChannelMonth';

export const ReportPage = () => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <RevenueByMonth />
        </Grid>
        <Grid item xs={12} md={4}>
          <RevenueByChannel />
        </Grid>
        <Grid item xs={12} md={4}>
          <NetRevenueByChannel />
        </Grid>
        <Grid item xs={12} md={12}>
          <NetRevenueByChannelAndMonth />
        </Grid>
        <Grid item xs={12} md={6}>
          <EquipmentRepresentationByValue />
        </Grid>
        <Grid item xs={12} md={6}>
          <EquipmentRepresentationByChannel />
        </Grid>
        <Grid item xs={12} md={12}>
          <RevenueEquipmentByMonth />
        </Grid>
        <Grid item xs={12} md={12}>
          <AvgTicketWithConsolidatedQuantity />
        </Grid>
        <Grid item xs={12} md={6}>
          <ChannelAvgTicketWithConsolidatedQuantity channel="Distribuidor Brasil" />
        </Grid>
        <Grid item xs={12} md={6}>
          <ChannelAvgTicketWithConsolidatedQuantity channel="Exportação" />
        </Grid>
        <Grid item xs={12} md={6}>
          <ChannelAvgTicketWithConsolidatedQuantity channel="Não Identificado" />
        </Grid>
        <Grid item xs={12} md={6}>
          <ChannelAvgTicketWithConsolidatedQuantity channel="Venda Direta/Representantes - Estética" />
        </Grid>
        <Grid item xs={12} md={12}>
          <MarkupByMonth />
        </Grid>
        <Grid item xs={12} md={12}>
          <MarkupByChannelMonth />
        </Grid>
      </Grid>
    </>
  );
};
