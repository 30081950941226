import React, { createContext, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ApiResults } from '../../../models/ApiResults';
import { Availability } from '../../../models/Availability';
import { IPositionAvailabilityValue } from '../../../models/PositionAvailabilityValue';
import { PositionAvailabilityValueService } from '../../../services/PositionAvailabilityValue.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { checkResponseStatus } from '../../../utils/api/response';

export interface AvailabilityFilterFields extends Availability {
  order_by?: string;
  page?: number;
  page_size?: number;
  search?: string;
}

export interface PositionAvailabilityValuesResult extends ApiResults {
  results: IPositionAvailabilityValue;
}

interface AvailabilityContextProps {
  positionAvailabilityValues: PositionAvailabilityValuesResult | undefined;
  fetchPositionAvailabilityValues: () => void;
}

const PositionAvailabilityValuesContext = createContext<AvailabilityContextProps | undefined>(undefined);

interface Props {
  children: React.ReactNode;
}

export const PositionAvailabilityValueContextProvider = ({ children }: Props) => {
  const dispatch = useDispatch();
  const [positionAvailabilityValues, setPositionAvailabilityValues] = useState<PositionAvailabilityValuesResult | undefined>(undefined);

  const fetchPositionAvailabilityValues = async () => {
    dispatch(showSpinner());

    try {
      const response = await PositionAvailabilityValueService.get();
      if (checkResponseStatus(response)) {
        setPositionAvailabilityValues(response?.data);
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({ title: 'Erro', message: error?.data || 'Houve um erro ao processar a sua solicitação', severity: 'error' })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  useEffect(() => {
    fetchPositionAvailabilityValues();
  }, []);

  const contextValue: AvailabilityContextProps = {
    positionAvailabilityValues,
    fetchPositionAvailabilityValues
  };

  return <PositionAvailabilityValuesContext.Provider value={contextValue}>{children}</PositionAvailabilityValuesContext.Provider>;
};

export const usePositionAvailabilityValuesContext = () => {
  const context = useContext(PositionAvailabilityValuesContext);
  if (!context) {
    throw new Error('usePositionAvailabilityValuesContext must be used within a PositionAvailabilityValuesContextProvider');
  }
  return context;
};
