import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { BaseTextFieldProps, TextField } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import { FocusEventHandler, Ref, SyntheticEvent, useEffect, useState } from 'react';
import { Control, Controller } from 'react-hook-form';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface Params {
  getOptionLabel: (option: SelectOption) => string;
  inputValue: string;
}

export interface SelectOption {
  inputValue?: string;
  id?: any;
  name?: string;
}

interface Props extends BaseTextFieldProps {
  control: Control<any>;
  name: string;
  label?: string;
  errorMessage?: string;
  helperText?: string;
  defaultValue?: SelectOption | SelectOption[];
  selectOptions: SelectOption[] | undefined;
  showCreateNew?: boolean;
  multiple?: boolean;
  disabled?: boolean;
  size?: 'small' | 'medium';
  autoFocus?: boolean;
  variant?: 'standard' | 'outlined' | 'filled';
  required?: boolean;
}

export const ControlledComboBox = ({
  control,
  name,
  label,
  errorMessage,
  helperText,
  defaultValue,
  selectOptions,
  showCreateNew = false,
  multiple = false,
  disabled,
  size = 'small',
  autoFocus = false,
  variant = 'outlined',
  required = false,
  ...rest
}: Props) => {
  const [options, setOptions] = useState<SelectOption[]>(selectOptions ? selectOptions : []);

  useEffect(() => {
    setOptions(selectOptions?.length ? selectOptions : []);
  }, [selectOptions]);

  const filter = createFilterOptions<SelectOption>();

  const handleFilterOptions = (options: SelectOption[], params: Params, value: SelectOption | SelectOption[] | null | undefined) => {
    const filtered = filter(options, params);
    let { inputValue } = params;
    inputValue = inputValue.trim();

    // Suggest the creation of a new value
    if (showCreateNew) {
      const isExisting = options?.some((option: SelectOption) => inputValue === option?.name);
      if (inputValue !== '' && !isExisting) {
        let newOption = {
          id: 0,
          name: inputValue,
          inputValue: `${inputValue} (criar novo)`
        };

        filtered.push(newOption);
      }
    }
    return filtered;
  };

  const handleOnChange = (
    event: SyntheticEvent<Element, Event>,
    newValue: SelectOption | SelectOption[] | null,
    onChange: { (...event: any[]): void; (arg0: any): void }
  ) => {
    onChange(newValue);
    if (newValue) {
      let lastInputName: string | undefined;

      //se for multiple, pega o ultimo valor do array, se não, pega o valor direto
      if (Array.isArray(newValue)) {
        lastInputName = newValue[newValue?.length - 1]?.name;
      } else {
        lastInputName = newValue?.name;
      }
      if (lastInputName && !options.find((option) => option?.name == lastInputName)) {
        setOptions([...options, { id: 0, name: lastInputName }]);
      }
    }
  };

  const getOptionLabel = (option: SelectOption) => {
    return String(option?.name ? option.name : '');
  };

  const _renderAutocomplete = (
    onChange: (...event: any[]) => void,
    onBlur: FocusEventHandler<HTMLDivElement> | undefined,
    value: SelectOption | SelectOption[] | null | undefined,
    ref: Ref<unknown> | undefined
  ) => {
    return (
      <Autocomplete
        noOptionsText="Nenhum resultado encontrado"
        onChange={(event, newValue) => handleOnChange(event, newValue, onChange)}
        onBlur={onBlur}
        value={value}
        ref={ref}
        fullWidth
        size="small"
        disabled={disabled}
        multiple={multiple}
        options={options}
        disableCloseOnSelect={multiple}
        getOptionLabel={(option) => getOptionLabel(option)}
        filterOptions={(options: SelectOption[], params: Params) => handleFilterOptions(options, params, value)}
        isOptionEqualToValue={(option: SelectOption, value: SelectOption) => {
          if (value?.name != '') {
            if (option?.name == value?.name && option?.id == value?.id) {
              return true;
            } else {
              return false;
            }
          }
          return true;
        }}
        renderOption={(props, option, { selected }) => {
          return (
            <li {...props} key={`${option?.id}.${option?.name}`}>
              {multiple && (
                <Checkbox
                  icon={icon}
                  checkedIcon={multiple ? checkedIcon : null}
                  style={{ marginRight: 8 }}
                  checked={selected}
                  size="small"
                  key={`${option?.id}.${option?.name}`}
                />
              )}
              {option?.inputValue ? option?.inputValue : option?.name}
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            {...rest}
            label={label}
            error={!!errorMessage}
            helperText={errorMessage ? errorMessage : helperText}
            size={size}
            variant={variant}
            required={required}
            autoFocus={autoFocus}
          />
        )}
      />
    );
  };

  return (
    <>
      {control && name && (
        <Controller
          control={control}
          name={name}
          defaultValue={multiple ? (defaultValue ? defaultValue : []) : defaultValue}
          render={({ field: { onChange, onBlur, value, ref } }) => _renderAutocomplete(onChange, onBlur, value, ref)}
        />
      )}
    </>
  );
};
