import { yupResolver } from '@hookform/resolvers/yup';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Alert, Divider, Grid, InputLabel } from '@mui/material'; // Import Tab components
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { ControlledTextInput } from '../../../components/basics/ControlledTextInput';
import { FormMessage } from '../../../models/FormMessage';
import { PaymentOrder } from '../../../models/PaymentOrder';
import { PaymentOrdersService } from '../../../services/PaymentOrder.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { ServiceOrderSchema } from '../../../utils/forms/validations/formValidations';
import { formatDate, formatDateTimeToApi, setInputErrorsFromApi } from '../../../utils/utils';
import { usePaymentOrdersPageContext } from '../context/PaymentOrderPageContext';

interface Props {
  editItem?: PaymentOrder;
  onClose: () => void;
}

export const PaymentOrderFormDialog = ({ editItem, onClose }: Props) => {
  const dispatch = useDispatch();
  const [formMessage, setFormMessage] = useState<FormMessage | null>(null);

  const { fetchRepresetativeLinks } = usePaymentOrdersPageContext();

  const getFormattedToday = () => {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const year = today.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const {
    control,
    handleSubmit,
    setError,
    setValue,
    formState: { errors }
  } = useForm<PaymentOrder>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(ServiceOrderSchema),
    defaultValues: editItem
      ? {
          ...editItem,
          date: editItem.date ? formatDate(editItem.date) : '',
          value: editItem.value?.toString().replace('.', ',')
        }
      : {
          date: getFormattedToday()
        }
  });

  const onSubmitForm: SubmitHandler<PaymentOrder> = async (data: PaymentOrder) => {
    data = {
      ...data,
      date: data?.date ? formatDateTimeToApi(data.date, true) : null,
      value: data.value ? data.value.toString().replace('$ ', '').replace('.', '').replace(',', '.') : null,
      company: 1 //fixed value
    };
    dispatch(showSpinner());

    try {
      const response = editItem?.company ? await PaymentOrdersService.update(data) : await PaymentOrdersService.create(data);
      if (response) {
        dispatch(
          showSnackbarAlert({
            title: 'Sucesso!',
            message: editItem?.company ? 'Ordem atualizada com sucesso!' : 'Ordem criada com sucesso!',
            severity: 'success'
          })
        );
      }
      fetchRepresetativeLinks();
      onClose();
    } catch (error: any) {
      setFormMessage({
        severity: 'error',
        message: setInputErrorsFromApi(setError, error.data) || ''
      });
    } finally {
      dispatch(hideSpinner());
    }
  };

  return (
    <Dialog open={true} onClose={onClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        {editItem?.id ? 'Editar' : 'Nova'} Ordem de Pagamento
      </DialogTitle>
      <Divider />
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {formMessage?.message && (
                <Grid item xs={12}>
                  <Alert severity={formMessage?.severity} variant="outlined" sx={{ width: '100%' }}>
                    {formMessage.message}
                  </Alert>
                </Grid>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="date">Data</InputLabel>
              <ControlledTextInput
                name="date"
                placeholder="Data"
                control={control}
                mask="99/99/9999"
                errorMessage={errors.date?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="value">Valor</InputLabel>
              <ControlledTextInput
                name="value"
                placeholder="Valor"
                control={control}
                errorMessage={errors.value?.message?.toString()}
                currency={true}
                dollar={true}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ mx: 2, my: 1 }}>
          <Button startIcon={<CloseIcon />} variant="outlined" onClick={onClose}>
            Cancelar
          </Button>
          <Button startIcon={<CheckIcon />} variant="contained" type="submit" onClick={handleSubmit(onSubmitForm)}>
            Salvar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
