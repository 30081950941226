import { HelmetMeta } from '../../../layout/HelmetMeta';
import { UsersPage } from '../../../sections/@users-page/UsersPage';
import { UsersPageContextProvider } from '../../../sections/@users-page/context/UsersPageContext';

export const UsersPageWrapper = () => (
  <UsersPageContextProvider>
    <HelmetMeta pageName="Usuários" />
    <UsersPage />
  </UsersPageContextProvider>
);
