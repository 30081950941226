import FilterListIcon from '@mui/icons-material/FilterList';
import { Button, Tooltip } from '@mui/material';
import { debounce } from 'lodash';
import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useCrudPermissions } from '../../../hooks/useCrudPermissions';
import { IntegrationLog } from '../../../models/IntegrationLog';
import { IntegrationLogDetailsDialog } from '../IntegrationLogDetailsDialog';
import { IntegrationLogFilterDialog } from '../IntegrationLogFilterDialog';
import { IntegrationLogTable } from '../IntegrationLogTable';
import { useIntegrationLogsPageContext } from '../context/IntegrationLogPageContext';
import { ButtonsContainer } from './styles';

export const IntegrationLogPage = () => {
  const { permissions } = useCrudPermissions({
    submodule: 'INTEGRATION_LOG'
  });

  const [showFilter, setShowFilter] = useState(false);
  const [viewItem, setViewItem] = useState<IntegrationLog | undefined>(undefined);

  const { control } = useForm();
  const watchSearch = useWatch({
    control,
    name: 'search'
  });

  const { filterFields, setFilterFields } = useIntegrationLogsPageContext();

  const handleViewItem = (item: IntegrationLog) => {
    setViewItem(item);
  };

  const handleShowFilter = () => {
    setShowFilter(true);
  };

  const handleCloseDialogs = () => {
    setShowFilter(false);
    setViewItem(undefined);
  };

  useEffect(() => {
    if (watchSearch) {
      const debounced = debounce(() => {
        setFilterFields({
          ...filterFields,
          search: watchSearch
        });
      }, 500);
      debounced();
      return debounced.cancel;
    }
  }, [watchSearch]);

  return (
    <>
      <ButtonsContainer>
        {permissions.hasReadPermission && (
          <>
            <Tooltip title="Filtros">
              <Button variant="outlined" color="primary" startIcon={<FilterListIcon />} sx={{ mr: 1 }} onClick={handleShowFilter}>
                Filtros
              </Button>
            </Tooltip>
          </>
        )}
      </ButtonsContainer>
      <IntegrationLogTable onView={handleViewItem} permissions={permissions} />
      {showFilter && permissions.hasReadPermission && <IntegrationLogFilterDialog onClose={handleCloseDialogs} />}
      {viewItem && <IntegrationLogDetailsDialog item={viewItem} onClose={handleCloseDialogs} />}
    </>
  );
};
