import { HelmetMeta } from '../../../layout/HelmetMeta';
import { SaleDivisionLinkPage } from '../../../sections/@link-sales-division/SaleDivisionLinkPage';
import { SaleDivisionLinkPageContextProvider } from '../../../sections/@link-sales-division/context/SaleDivisionLinkPageContext';

export const LinkSaleDivisionPageWrapper = () => (
  <SaleDivisionLinkPageContextProvider>
    <HelmetMeta pageName="Vinculo de Divisao de Venda" />
    <SaleDivisionLinkPage />
  </SaleDivisionLinkPageContextProvider>
);
