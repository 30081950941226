import React, { createContext, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ApiResults } from '../../../models/ApiResults';
import { IntegrationLog } from '../../../models/IntegrationLog';
import { IntegrationLogService } from '../../../services/IntegrationLog.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { checkResponseStatus } from '../../../utils/api/response';

export interface IntegrationLogFilterFields extends IntegrationLog {
  order_by?: string;
  page?: number;
  page_size?: number;
  search?: string;
  has_error?: number;
  start_date?: string;
  end_date?: string;
}

export interface IntegrationLogResult extends ApiResults {
  results: IntegrationLog[];
}

interface IntegrationLogContextProps {
  integrationLogs: IntegrationLogResult | undefined;
  fetchIntegrationLogs: () => void;
  filterFields: IntegrationLogFilterFields;
  setFilterFields: React.Dispatch<React.SetStateAction<IntegrationLogFilterFields>>;
  page: number;
  rowsPerPage: number;
  handleOrderChange: (property: string, order: 'asc' | 'desc') => void;
  handlePageChange: (page: number) => void;
  handleOnChangeRowsPerPage: (page_size: number) => void;
}

const IntegrationLogsPageContext = createContext<IntegrationLogContextProps | undefined>(undefined);

interface Props {
  children: React.ReactNode;
}

export const IntegrationLogPageContextProvider = ({ children }: Props) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(50);
  const [integrationLogs, setIntegrationLogs] = useState<IntegrationLogResult | undefined>(undefined);
  const [filterFields, setFilterFields] = useState<IntegrationLogFilterFields>({} as IntegrationLogFilterFields);

  const fetchIntegrationLogs = async () => {
    dispatch(showSpinner());

    try {
      const response = await IntegrationLogService.get(filterFields, page, rowsPerPage);
      if (checkResponseStatus(response)) {
        setIntegrationLogs(response?.data);
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({ title: 'Erro', message: error?.data || 'Houve um erro ao processar a sua solicitação', severity: 'error' })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const handleOrderChange = (property: string, order: 'asc' | 'desc') => {
    const orderFilter = order === 'asc' ? property : `-${property}`;
    setFilterFields({ ...filterFields, order_by: orderFilter });
  };

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handleOnChangeRowsPerPage = (page_size: number) => {
    setRowsPerPage(page_size);
  };

  useEffect(() => {
    fetchIntegrationLogs();
  }, [filterFields, page, rowsPerPage]);

  const contextValue: IntegrationLogContextProps = {
    integrationLogs,
    fetchIntegrationLogs,
    filterFields,
    setFilterFields,
    page,
    rowsPerPage,
    handleOrderChange,
    handlePageChange,
    handleOnChangeRowsPerPage
  };

  return <IntegrationLogsPageContext.Provider value={contextValue}>{children}</IntegrationLogsPageContext.Provider>;
};

export const useIntegrationLogsPageContext = () => {
  const context = useContext(IntegrationLogsPageContext);
  if (!context) {
    throw new Error('useIntegrationLogsPageContext must be used within a IntegrationLogsPageContextProvider');
  }
  return context;
};
