import { HelmetMeta } from '../../../layout/HelmetMeta';
import { IntegrationLogPage } from '../../../sections/@integration-log/IntegrationLogPage';
import { IntegrationLogPageContextProvider } from '../../../sections/@integration-log/context/IntegrationLogPageContext';

export const IntegrationLogPageWrapper = () => (
  <IntegrationLogPageContextProvider>
    <HelmetMeta pageName="Logs da Integração" />
    <IntegrationLogPage />
  </IntegrationLogPageContextProvider>
);
