import CheckIcon from '@mui/icons-material/Check';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import CloseIcon from '@mui/icons-material/Close';
import { Divider, Grid, InputLabel } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { ControlledComboBox, SelectOption } from '../../../components/basics/ControlledComboBox';
import { ControlledTextInput } from '../../../components/basics/ControlledTextInput';
import { RDUser } from '../../../models/RDUser';
import { RDCompanyService } from '../../../services/RDCompany.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { findSelectedOption } from '../../../utils/utils';
import { CustomerLinkFilterFields, useCustomerLinksPageContext } from '../context/CustomerLinkPageContext';
interface Props {
  onClose: () => void;
}

const filterPersonTypeOptions: SelectOption[] = [
  { id: 'Física', name: 'Física' },
  { id: 'Jurídica', name: 'Jurídica' }
];

const getDefaultValues = (filter?: CustomerLinkFilterFields): CustomerLinkFilterFields => {
  return {
    focco_customer_name: filter?.focco_customer_name ?? '',
    focco_customer_trade_name: filter?.focco_customer_trade_name ?? '',
    focco_customer_person_type:
      findSelectedOption(filterPersonTypeOptions, filter?.focco_customer_person_type) ?? filterPersonTypeOptions[0].id,
    focco_customer_cnpj_cpf: filter?.focco_customer_cnpj_cpf ?? ''
  };
};

export const CustomerLinkFilterDialog = ({ onClose }: Props) => {
  const dispatch = useDispatch();
  const { filterFields, setFilterFields } = useCustomerLinksPageContext();
  const [rdCompanies, setRDCompanies] = useState<SelectOption[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors }
  } = useForm<CustomerLinkFilterFields>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: getDefaultValues(filterFields)
  });

  const onSubmit: SubmitHandler<CustomerLinkFilterFields> = async (data: CustomerLinkFilterFields) => {
    if (data.focco_customer_person_type?.id != null) {
      data.focco_customer_person_type = data.focco_customer_person_type.id;
    } else {
      delete data.focco_customer_person_type;
    }
    data = {
      ...data,
      rd_company: data?.rd_company ? data.rd_company.id : null
    };
    setFilterFields({ ...data } as CustomerLinkFilterFields);
    onClose();
  };

  const handleClear = () => {
    setFilterFields({} as CustomerLinkFilterFields);
    onClose();
  };

  const fetchRDCompanies = async () => {
    try {
      const response = await RDCompanyService.getAll();
      if (response?.data?.results) {
        const dataRDUsers = response?.data?.results;
        setRDCompanies(
          dataRDUsers.map((user: RDUser) => ({
            id: user.id,
            name: user.name
          }))
        );
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: 'Erro ao buscar Empresas do RD Station',
          severity: 'error'
        })
      );
    }
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      dispatch(showSpinner());

      await fetchRDCompanies();

      setIsLoading(false);
      dispatch(hideSpinner());
    })();
  }, []);

  useEffect(() => {
    if (filterFields && filterFields.rd_company) {
      const selectedOption = rdCompanies.find((option) => option.id === filterFields.rd_company);
      if (selectedOption) {
        setValue('rd_company', selectedOption);
      }
    }
  }, [rdCompanies]);

  return (
    <Dialog open={!isLoading} onClose={onClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        Filtros
      </DialogTitle>
      <Divider />
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="focco_customer_name">Nome do Cliente</InputLabel>
              <ControlledTextInput
                name="focco_customer_name"
                placeholder="Nome Cliente"
                control={control}
                errorMessage={errors.focco_customer?.name?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="focco_customer_trade_name">Nome Fantasia</InputLabel>
              <ControlledTextInput
                name="focco_customer_trade_name"
                placeholder="Nome Fantasia"
                control={control}
                errorMessage={errors.focco_customer?.trade_name?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel id="focco_customer_person_type">Tipo de Pessoa</InputLabel>
              <ControlledComboBox
                name="focco_customer_person_type"
                placeholder="Tipo de Pessoa"
                control={control}
                errorMessage={errors.focco_customer?.person_type?.message?.toString()}
                selectOptions={[
                  { id: 'Física', name: 'Física' },
                  { id: 'Jurídica', name: 'Jurídica' }
                ]}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="focco_customer_cnpj_cpf">CNPJ/CPF</InputLabel>
              <ControlledTextInput
                name="focco_customer_cnpj_cpf"
                placeholder="CNPJ/CPF"
                control={control}
                errorMessage={errors.focco_customer?.cnpj_cpf?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="rd_company">Empresa RD</InputLabel>
              <ControlledComboBox
                name="rd_company"
                placeholder="Empresa RD"
                control={control}
                errorMessage={errors.rd_company?.message?.toString()}
                selectOptions={rdCompanies}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ mx: 2, my: 1 }}>
          <Button startIcon={<ClearAllIcon />} variant="outlined" onClick={handleClear}>
            Limpar
          </Button>
          <Button startIcon={<CloseIcon />} variant="outlined" onClick={onClose}>
            Fechar
          </Button>
          <Button startIcon={<CheckIcon />} variant="contained" type="submit" onClick={handleSubmit(onSubmit)}>
            Filtrar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
