import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  Typography,
  useTheme
} from '@mui/material';
import { useState } from 'react';
import { DialogHeader } from '../../../components/basics/DialogHeader';
import { PermissionGroup } from '../../../models/PermissionGroup';

interface Props {
  item: PermissionGroup;
  onClose: () => void;
}

export const PermissionGroupsDetailsDialog = ({ item, onClose }: Props) => {
  const theme = useTheme();
  const [expandedModules, setExpandedModules] = useState<string[]>([]);

  const handleToggleExpansion = (moduleLabel: string) => {
    setExpandedModules((prevExpandedModules) =>
      prevExpandedModules.includes(moduleLabel)
        ? prevExpandedModules.filter((label) => label !== moduleLabel)
        : [...prevExpandedModules, moduleLabel]
    );
  };

  const groupedPermissions: Record<string, Record<string, string[]>> = {};
  item?.permissions?.forEach((permission: any) => {
    if (!groupedPermissions[permission.module_label]) {
      groupedPermissions[permission.module_label] = {};
    }
    if (!groupedPermissions[permission.module_label][permission.submodule_label]) {
      groupedPermissions[permission.module_label][permission.submodule_label] = [];
    }
    groupedPermissions[permission.module_label][permission.submodule_label].push(permission.permission_label);
  });

  return (
    <Dialog open={!!item} onClose={onClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogHeader title="Detalhes do grupo de permissões" onClose={onClose} />
      <Divider />
      <DialogContent>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom>
              Nome do Grupo
            </Typography>
            <Typography variant="body1" color="secondary" gutterBottom>
              {item.name}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">Permissões do Grupo (clique para expandir)</Typography>
            <Box sx={{ mt: 2 }}>
              {Object.keys(groupedPermissions).map((moduleLabel) => (
                <Accordion
                  key={moduleLabel}
                  expanded={expandedModules.includes(moduleLabel)}
                  onChange={() => handleToggleExpansion(moduleLabel)}
                  elevation={1}
                  sx={{ mb: 1 }}
                >
                  <AccordionSummary expandIcon={expandedModules.includes(moduleLabel) ? <ExpandLessIcon /> : <ExpandMoreIcon />}>
                    <Typography variant="h6" color="textPrimary" sx={{ textDecoration: 'underline' }}>
                      {moduleLabel}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {Object.keys(groupedPermissions[moduleLabel]).map((submoduleLabel) => (
                      <div key={submoduleLabel} style={{ background: theme.palette.grey[100], padding: '5px', marginBottom: '5px' }}>
                        <Typography variant="body1" color="secondary.dark">{`Submódulo: ${submoduleLabel}`}</Typography>
                        <Typography variant="body1" color="secondary.main">{`Permissões: ${groupedPermissions[moduleLabel][
                          submoduleLabel
                        ].join(', ')}`}</Typography>
                      </div>
                    ))}
                  </AccordionDetails>
                </Accordion>
              ))}
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
