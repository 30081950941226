import * as yup from 'yup';

export const loginValidations = yup.object().shape({
  username: yup.string().required('Usuário é obrigatório'),
  password: yup.string().required('Senha é obrigatória')
});

export const registerValidations = yup.object().shape({
  email: yup.string().email('Email inválido').required('Email é obrigatório'),
  nome_responsavel: yup.string().required('Nome do responsável é obrigatório'),
  telefone: yup.string().required('Telefone é obrigatório')
});

export const CompanySchema = yup.object().shape({
  company_name: yup.string().required('Nome é obrigatório'),
  trade_name: yup.string().required('Nome fantasia é obrigatório')
});

export const DefectSchema = yup.object().shape({
  description: yup.string().required('Descrição é obrigatória')
});

export const ProductSchema = yup.object().shape({
  serial_number: yup.string().required('Número de série é obrigatório'),
  quality_sequence: yup.string().required('Sequência de qualidade é obrigatória')
});

export const UserSchema = yup.object().shape({
  username: yup.string().required('Usuário é obrigatório'),
  email: yup.string().email('Email inválido').required('Email é obrigatório')
});

export const UserPasswordEditSchema = yup.object().shape({
  current_password: yup.string().required('Senha Atual é obrigatória'),
  confirm_password: yup.string().required('Confirmação de Senha é obrigatória'),
  password: yup.string().required('Nova Senha é obrigatória')
});

export const UserPermissionSchema = yup.object().shape({});

export const PermissionGroupSchema = yup.object().shape({});

export const ClientsSchema = yup.object().shape({});

export const ExpenseSchema = yup.object().shape({});

export const ManualSchema = yup.object().shape({});

export const ServiceOrderSchema = yup.object().shape({});

export const SaleDivisionLinkSchema = yup.object().shape({
  funnel_stage: yup.object().nullable().required('Selecione uma etapa do funil.'),
  type: yup.object().nullable().required('Selecione um tipo.')
});
export const AvailabilityScheme = yup.object().shape({
  description: yup.string().required('A descrição é obrigatório'),
  order: yup.number().typeError('A ordem é obrigatória').required('A ordem é obrigatória'),
  situation: yup.boolean().required('A situação é obrigatória'),
  sum_assets: yup.boolean().required('A indicação de soma é obrigatória')
});
export const AvailabilityValueScheme = yup.object().shape({});

export const CustomerLinkSchema = yup.object().shape({});
export const ProductLinkSchema = yup.object().shape({});
