import React, { createContext, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ApiResults } from '../../../models/ApiResults';
import { CustomerLink } from '../../../models/CustomerLink';
import { CustomerLinkService } from '../../../services/CustomerLink.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { checkResponseStatus } from '../../../utils/api/response';

export interface CustomerLinkFilterFields extends CustomerLink {
  order_by?: string;
  page?: number;
  page_size?: number;
  search?: string;
  focco_customer_name?: string;
  focco_customer_trade_name?: string;
  focco_customer_person_type?: string;
  focco_customer_cnpj_cpf?: string;
}

export interface CustomerLinkResult extends ApiResults {
  results: CustomerLink[];
}

interface CustomerLinkContextProps {
  customerLinks: CustomerLinkResult | undefined;
  fetchCustomerLinks: () => void;
  deleteCustomerLinks: (id: number) => Promise<void>;
  deleteManyCustomerLinks: (items: string[]) => Promise<void>;
  filterFields: CustomerLinkFilterFields;
  setFilterFields: React.Dispatch<React.SetStateAction<CustomerLinkFilterFields>>;
  page: number;
  rowsPerPage: number;
  handleOrderChange: (property: string, order: 'asc' | 'desc') => void;
  handlePageChange: (page: number) => void;
  handleOnChangeRowsPerPage: (page_size: number) => void;
}

const CustomerLinksPageContext = createContext<CustomerLinkContextProps | undefined>(undefined);

interface Props {
  children: React.ReactNode;
}

export const CustomerLinkPageContextProvider = ({ children }: Props) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(50);
  const [customerLinks, setCustomerLinks] = useState<CustomerLinkResult | undefined>(undefined);
  const [filterFields, setFilterFields] = useState<CustomerLinkFilterFields>({} as CustomerLinkFilterFields);

  const fetchCustomerLinks = async () => {
    dispatch(showSpinner());

    try {
      const response = await CustomerLinkService.get(filterFields, page, rowsPerPage);
      if (checkResponseStatus(response)) {
        setCustomerLinks(response?.data);
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({ title: 'Erro', message: error?.data || 'Houve um erro ao processar a sua solicitação', severity: 'error' })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const deleteCustomerLinks = async (id: number) => {
    dispatch(showSpinner());
    try {
      await CustomerLinkService.delete(id);
      dispatch(
        showSnackbarAlert({
          title: 'Sucesso',
          message: 'Registro excluído com sucesso!',
          severity: 'success'
        })
      );
      fetchCustomerLinks();
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: error.data.detail || 'Houve um erro ao processar a sua solicitação',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const deleteManyCustomerLinks = async (items: string[]) => {
    dispatch(showSpinner());
    try {
      await CustomerLinkService.deleteMany(items);
      dispatch(
        showSnackbarAlert({
          title: 'Sucesso',
          message: 'Registros excluídos com sucesso!',
          severity: 'success'
        })
      );
      fetchCustomerLinks();
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: error.data.detail ?? 'Houve um erro ao processar a sua solicitação',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const handleOrderChange = (property: string, order: 'asc' | 'desc') => {
    const orderFilter = order === 'asc' ? property : `-${property}`;
    setFilterFields({ ...filterFields, order_by: orderFilter });
  };

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handleOnChangeRowsPerPage = (page_size: number) => {
    setRowsPerPage(page_size);
  };

  useEffect(() => {
    fetchCustomerLinks();
  }, [filterFields, page, rowsPerPage]);

  const contextValue: CustomerLinkContextProps = {
    customerLinks,
    fetchCustomerLinks,
    deleteCustomerLinks,
    deleteManyCustomerLinks,
    filterFields,
    setFilterFields,
    page,
    rowsPerPage,
    handleOrderChange,
    handlePageChange,
    handleOnChangeRowsPerPage
  };

  return <CustomerLinksPageContext.Provider value={contextValue}>{children}</CustomerLinksPageContext.Provider>;
};

export const useCustomerLinksPageContext = () => {
  const context = useContext(CustomerLinksPageContext);
  if (!context) {
    throw new Error('useCustomerLinksPageContext must be used within a CustomerLinksPageContextProvider');
  }
  return context;
};
