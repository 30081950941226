import { HelmetMeta } from '../../../layout/HelmetMeta';
import { ProductLinkPage } from '../../../sections/@link-product/ProductLinkPage';
import { ProductLinkPageContextProvider } from '../../../sections/@link-product/context/ProductLinkPageContext';

export const LinkProductPageWrapper = () => (
  <ProductLinkPageContextProvider>
    <HelmetMeta pageName="Vinculo de Produto" />
    <ProductLinkPage />
  </ProductLinkPageContextProvider>
);
