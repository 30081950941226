import React, { createContext, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ApiResults } from '../../../models/ApiResults';
import { Availability } from '../../../models/Availability';
import { AvailabilityService } from '../../../services/Availability.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { checkResponseStatus } from '../../../utils/api/response';

export interface AvailabilityFilterFields extends Availability {
  order_by?: string;
  page?: number;
  page_size?: number;
  search?: string;
}

export interface AvailabilityResult extends ApiResults {
  results: Availability[];
}

interface AvailabilityContextProps {
  availabilities: AvailabilityResult | undefined;
  fetchAvailabilities: () => void;
  deleteAvailabilitys: (id: number) => Promise<void>;
  deleteManyAvailabilitys: (items: string[]) => Promise<void>;
  filterFields: AvailabilityFilterFields;
  setFilterFields: React.Dispatch<React.SetStateAction<AvailabilityFilterFields>>;
  page: number;
  rowsPerPage: number;
  handleOrderChange: (property: string, order: 'asc' | 'desc') => void;
  handlePageChange: (page: number) => void;
  handleOnChangeRowsPerPage: (page_size: number) => void;
}

const AvailabilitysPageContext = createContext<AvailabilityContextProps | undefined>(undefined);

interface Props {
  children: React.ReactNode;
}

export const AvailabilityPageContextProvider = ({ children }: Props) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(50);
  const [availabilities, setAvailabilitys] = useState<AvailabilityResult | undefined>(undefined);
  const [filterFields, setFilterFields] = useState<AvailabilityFilterFields>({} as AvailabilityFilterFields);

  const fetchAvailabilities = async () => {
    dispatch(showSpinner());

    try {
      const response = await AvailabilityService.get(filterFields, page, rowsPerPage);
      if (checkResponseStatus(response)) {
        setAvailabilitys(response?.data);
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({ title: 'Erro', message: error?.data || 'Houve um erro ao processar a sua solicitação', severity: 'error' })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const deleteAvailabilitys = async (id: number) => {
    dispatch(showSpinner());
    try {
      await AvailabilityService.delete(id);
      dispatch(
        showSnackbarAlert({
          title: 'Sucesso',
          message: 'Registro excluído com sucesso!',
          severity: 'success'
        })
      );
      fetchAvailabilities();
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: error.data.detail || 'Houve um erro ao processar a sua solicitação',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const deleteManyAvailabilitys = async (items: string[]) => {
    dispatch(showSpinner());
    try {
      await AvailabilityService.deleteMany(items);
      dispatch(
        showSnackbarAlert({
          title: 'Sucesso',
          message: 'Registros excluídos com sucesso!',
          severity: 'success'
        })
      );
      fetchAvailabilities();
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: error.data.detail ?? 'Houve um erro ao processar a sua solicitação',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const handleOrderChange = (property: string, order: 'asc' | 'desc') => {
    const orderFilter = order === 'asc' ? property : `-${property}`;
    setFilterFields({ ...filterFields, order_by: orderFilter });
  };

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handleOnChangeRowsPerPage = (page_size: number) => {
    setRowsPerPage(page_size);
  };

  useEffect(() => {
    fetchAvailabilities();
  }, [filterFields, page, rowsPerPage]);

  const contextValue: AvailabilityContextProps = {
    availabilities,
    fetchAvailabilities,
    deleteAvailabilitys,
    deleteManyAvailabilitys,
    filterFields,
    setFilterFields,
    page,
    rowsPerPage,
    handleOrderChange,
    handlePageChange,
    handleOnChangeRowsPerPage
  };

  return <AvailabilitysPageContext.Provider value={contextValue}>{children}</AvailabilitysPageContext.Provider>;
};

export const useAvailabilitysPageContext = () => {
  const context = useContext(AvailabilitysPageContext);
  if (!context) {
    throw new Error('useAvailabilitysPageContext must be used within a AvailabilitysPageContextProvider');
  }
  return context;
};
