import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BaseEcharts } from '../../../components/charts/BaseEcharts';
import { MarkupByMonthService } from '../../../services/Reports.service';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { formatValueToTwoDecimals } from '../utils';
import { getVerboseNameFromDate, toTitleCase } from '../../../utils/utils';

export const MarkupByMonth = () => {
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();

  const options = {
    tooltip: {
      trigger: 'axis',
      valueFormatter: formatValueToTwoDecimals
    },
    legend: {
      data: ['Markup']
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: Array.from(new Set(data.map((item) => toTitleCase(getVerboseNameFromDate(item.month)))))
    },
    yAxis: {
      type: 'value'
    },
    series: [
      {
        name: 'Markup',
        type: 'line',
        data: data.map((item) => item.value),
        label: {
          show: true,
          position: 'top',
          fontSize: 10,
          formatter: (params: any) => formatValueToTwoDecimals(params.value)
        }
      }
    ]
  };

  const fetchReport = async () => {
    setLoading(true);
    try {
      const response = await new MarkupByMonthService().listAll();
      if (response?.data?.results) {
        setData(response.data.results.report);
      }
    } catch (error: any) {
      const errorMessage = error?.data?.detail || 'Erro ao buscar dados do Gráfico';
      dispatch(
        showSnackbarAlert({
          title: 'Erro!',
          message: errorMessage,
          severity: 'error'
        })
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchReport();
  }, []);

  return (
    <BaseEcharts
      title="Markup Realizado Total"
      option={options}
      dataLength={data.length}
      loading={loading}
      style={{ height: 400 }}
    />
  );
};
