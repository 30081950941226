import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BaseEcharts } from '../../../components/charts/BaseEcharts';
import { RevenueEquipmentByMonthService } from '../../../services/Reports.service';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { getVerboseNameFromDate, toTitleCase } from '../../../utils/utils';
import { formatValue } from '../utils';

export const RevenueEquipmentByMonth = () => {
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();

  const options = {
    tooltip: {
      trigger: 'axis',
      axisPointer: { type: 'shadow' }
    },
    xAxis: {
      type: 'category',
      data: data.map((item) => toTitleCase(getVerboseNameFromDate(item.month)))
    },
    yAxis: {
      type: 'value'
    },
    series: [
      {
        data: data.map((item) => item.value),
        type: 'bar',
        itemStyle: {
          color: '#96DAE4'
        },
        label: {
          show: true,
          position: 'top',
          fontSize: 10,
          formatter: (params: any) => formatValue(params.value)
        }
      }
    ]
  };

  const fetchReport = async () => {
    setLoading(true);
    try {
      const response = await new RevenueEquipmentByMonthService().listAll();
      if (response?.data?.results) {
        setData(response.data.results.report);
      }
    } catch (error: any) {
      const errorMessage = error?.data?.detail || 'Erro ao buscar dados do Gráfico';
      dispatch(
        showSnackbarAlert({
          title: 'Erro!',
          message: errorMessage,
          severity: 'error'
        })
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchReport();
  }, []);

  return (
    <BaseEcharts
      title="Quantidade de Equipamentos Faturados"
      subtitle={`Total: ${formatValue(data.reduce((acc, curr) => acc + curr.value, 0))}`}
      option={options}
      dataLength={data.length}
      loading={loading}
      style={{ height: 400 }}
    />
  );
};
