import { HelmetMeta } from '../../../layout/HelmetMeta';
import { PositionAvailabilityValueContextProvider } from '../../../sections/@position-availability-value/context/PositionAvailabilityValuePageContext';
import { PositionAvailabilityValuePage } from '../../../sections/@position-availability-value/PositionAvailabilityValuePage';

export const PositionAvailabilityValueWrapper = () => (
  <PositionAvailabilityValueContextProvider>
    <HelmetMeta pageName="Posição dos Saldos" />
    <PositionAvailabilityValuePage />
  </PositionAvailabilityValueContextProvider>
);
