import CheckIcon from '@mui/icons-material/Check';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import CloseIcon from '@mui/icons-material/Close';
import { Divider, Grid, InputLabel } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ControlledComboBox } from '../../../components/basics/ControlledComboBox';
import { ControlledTextInput } from '../../../components/basics/ControlledTextInput';
import { formatDate } from '../../../utils/utils';
import { AvailabilityFilterFields, useAvailabilitysPageContext } from '../context/AvailabilityPageContext';
interface Props {
  onClose: () => void;
}

const getDefaultValues = (filter?: AvailabilityFilterFields): AvailabilityFilterFields => {
  return {
    date: filter?.date ? formatDate(filter.date) : ''
  };
};

export const AvailabilityFilterDialog = ({ onClose }: Props) => {
  const { filterFields, setFilterFields } = useAvailabilitysPageContext();

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors }
  } = useForm<AvailabilityFilterFields>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: getDefaultValues(filterFields)
  });

  const onSubmit: SubmitHandler<AvailabilityFilterFields> = async (data: AvailabilityFilterFields) => {
    data = {
      ...data,
      situation: data?.situation?.id,
      sum_assets: data?.sum_assets?.id
    };
    setFilterFields({ ...data } as AvailabilityFilterFields);
    onClose();
  };

  const handleClear = () => {
    setFilterFields({} as AvailabilityFilterFields);
    onClose();
  };

  return (
    <Dialog open={true} onClose={onClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        Filtros
      </DialogTitle>
      <Divider />
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="description">Descrição</InputLabel>
              <ControlledTextInput
                name="description"
                placeholder="Descrição"
                control={control}
                errorMessage={errors.description?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="observation">Observação</InputLabel>
              <ControlledTextInput
                name="observation"
                placeholder="Observação"
                control={control}
                errorMessage={errors.observation?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="order">Ordem</InputLabel>
              <ControlledTextInput
                placeholder="Ordem"
                name="order"
                control={control}
                errorMessage={errors.order?.message?.toString()}
                type="number"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel id="situation">Situação</InputLabel>
              <ControlledComboBox
                name="situation"
                placeholder="Situação"
                control={control}
                errorMessage={errors.situation?.message?.toString()}
                selectOptions={[
                  { id: null, name: 'Todos' },
                  { id: 'A', name: 'Ativo' },
                  { id: 'I', name: 'Inativo' }
                ]}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel id="sum_assets">Soma Ativos</InputLabel>
              <ControlledComboBox
                name="sum_assets"
                placeholder="Soma Ativos"
                control={control}
                errorMessage={errors.sum_assets?.message?.toString()}
                selectOptions={[
                  { id: null, name: 'Todos' },
                  { id: true, name: 'Sim' },
                  { id: false, name: 'Não' }
                ]}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ mx: 2, my: 1 }}>
          <Button startIcon={<ClearAllIcon />} variant="outlined" onClick={handleClear}>
            Limpar
          </Button>
          <Button startIcon={<CloseIcon />} variant="outlined" onClick={onClose}>
            Fechar
          </Button>
          <Button startIcon={<CheckIcon />} variant="contained" type="submit" onClick={handleSubmit(onSubmit)}>
            Filtrar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
