// project import
import ScrollTop from './components/@extended/ScrollTop';
import { GlobalSpinner } from './components/basics/GlobalSpinner';
import { SnackbarAlertComponent } from './components/basics/SnackbarAlertComponent';
import { AuthContextProvider } from './context/AuthContextProvider';
import { Routes } from './routes';

import ThemeCustomization from './themes';

const App = () => {
  return (
    <ThemeCustomization>
      <ScrollTop>
        <AuthContextProvider>
          <GlobalSpinner />
          <SnackbarAlertComponent />
          <Routes />
        </AuthContextProvider>
      </ScrollTop>
    </ThemeCustomization>
  );
};

export default App;
