import { AxiosResponse, isAxiosError } from 'axios';
import { default as createAxiosInstance } from '../middlewares/axios.interceptors';
import { LoginFormData } from '../sections/@auth/auth-forms/AuthLogin';
import { User } from '../models/User';

const accountUrl = '/accounts/';
const loginUrl = '/accounts/auth/';
const accountConfirmation = '/auth/ativacao-conta';
const requestPasswordChange = '/auth/forgot-password';
const changePassword = '/auth/forgot-password';
const validateCpf = '/auth/verifica-cpf';
const userSessionInfo = '/accounts/';
const editPasswordUrl = '/accounts/edit-password/';

export const AuthService = {
  doLogin: async (data: LoginFormData) => {
    const axiosInstance = createAxiosInstance();

    try {
      return await axiosInstance.post(loginUrl, data);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  signUp: async (data: any) => {
    const axiosInstance = createAxiosInstance();

    try {
      return await axiosInstance.post('/accounts/', data);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  accountConfirmation: async (token: string) => {
    const axiosInstance = createAxiosInstance();

    try {
      return await axiosInstance.post(`${accountConfirmation}`, { token });
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  requestPasswordChange: async (email: string) => {
    const axiosInstance = createAxiosInstance();

    try {
      return await axiosInstance.post(requestPasswordChange, { email });
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  changePasswordValidateToken: async (token: string) => {
    const axiosInstance = createAxiosInstance();

    try {
      return await axiosInstance.get(`${changePassword}/${token}`);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  changePassword: async (token: string, data: { password: string; password_confirmation: string }) => {
    const axiosInstance = createAxiosInstance();

    try {
      return await axiosInstance.post(`${changePassword}/${token}`, data);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  validateCpf: async (cpf: string) => {
    const axiosInstance = createAxiosInstance();

    try {
      return await axiosInstance.get(validateCpf, {
        params: {
          cpf
        }
      });
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  getUserSessionData: async () => {
    const axiosInstance = createAxiosInstance();

    try {
      return await axiosInstance.get(userSessionInfo);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  partialUpdate: async (data: Partial<User>) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.put(accountUrl, data);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  editPassword: async (data: any) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.put(editPasswordUrl, data);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  }
};
