import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BaseEcharts } from '../../../components/charts/BaseEcharts';
import { MarkupByChannelMonthService } from '../../../services/Reports.service';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { getVerboseNameFromDate, toTitleCase } from '../../../utils/utils';
import { formatValueToTwoDecimals } from '../utils';

const convertToDataset = (data: any[]): (string | number)[][] => {
  const channels = Array.from(new Set(data.map((item) => item.channel)));
  const months = Array.from(new Set(data.map((item) => item.month)));
  const dataset: (string | number)[][] = [['channels', ...channels]];

  months.forEach((month) => {
    const row: (string | number)[] = [toTitleCase(getVerboseNameFromDate(month))];
    channels.forEach((channel) => {
      const entry = data.find((item) => item.month === month && item.channel === channel);
      row.push(entry ? entry.value : 0);
    });
    dataset.push(row);
  });

  return dataset;
};

export const MarkupByChannelMonth = () => {
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();

  const options = {
    legend: {
      top: 30
    },
    grid: {
      top: 100,
      bottom: 100
    },
    dataZoom: [
      {
        type: 'inside'
      },
      {
        type: 'slider',
        show: true
      }
    ],
    toolbox: {
      show: true,
      itemSize: 20,
      feature: {
        mark: { show: true },
        magicType: {
          type: ['stack', 'line', 'bar'],
          title: {
            stack: 'Pilha ',
            line: 'Linhas',
            bar: 'Barras'
          }
        }
      }
    },
    tooltip: {
      valueFormatter: formatValueToTwoDecimals
    },
    xAxis: {
      type: 'category'
    },
    yAxis: {},
    dataset: {
      source: convertToDataset(data)
    },
    series: Array.from(new Set(data.map((item) => item.channel))).map(() => ({
      type: 'bar',
      label: {
        show: true,
        position: 'top',
        align: 'left',
        verticalAlign: 'middle',
        rotate: 50,
        fontSize: 10,
        formatter: (params: any) => {
          {
            return formatValueToTwoDecimals(params.value[params.encode.y[0]]);
          }
        }
      }
    }))
  };

  const fetchReport = async () => {
    setLoading(true);
    try {
      const response = await new MarkupByChannelMonthService().listAll();
      if (response?.data?.results) {
        setData(response.data.results.report);
      }
    } catch (error: any) {
      const errorMessage = error?.data?.detail || 'Erro ao buscar dados do Gráfico';
      dispatch(
        showSnackbarAlert({
          title: 'Erro!',
          message: errorMessage,
          severity: 'error'
        })
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchReport();
  }, []);

  return (
    <BaseEcharts title="Markup Realizado Por Canal" option={options} dataLength={data.length} loading={loading} style={{ height: 600 }} />
  );
};
