// assets
import AddCardIcon from '@mui/icons-material/AddCard';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import { INavGroup } from '../models/Menu';
import { ROUTES } from '../routes/routes';

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

export const FinanceRoutes: INavGroup = {
  id: 'group-finance',
  title: 'Financeiro',
  icon: <CreditCardOutlinedIcon />,
  type: 'group',
  module: 'FINANCE',
  level: 1,
  children: [
    {
      id: 'payment_order',
      title: 'Ordem de Pagamento',
      type: 'item',
      url: ROUTES.authenticated.finance.payment_order,
      icon: <PaidOutlinedIcon />,
      breadcrumbs: true,
      submodule: 'PAYMENT_ORDER'
    },
    {
      id: 'availability',
      title: 'Disponibilidade',
      type: 'item',
      url: ROUTES.authenticated.finance.availability,
      icon: <AddCardIcon />,
      breadcrumbs: true,
      submodule: 'AVAILABILTY'
    },
    {
      id: 'availability_value',
      title: 'Saldo Disponibilidade',
      type: 'item',
      url: ROUTES.authenticated.finance.availability_value,
      icon: <CreditCardIcon />,
      breadcrumbs: true,
      submodule: 'AVAILABILTY_VALUES'
    },
    {
      id: 'position_availability_value',
      title: 'Posição dos Saldos',
      type: 'item',
      url: ROUTES.authenticated.finance.position_availability_value,
      icon: <CardMembershipIcon />,
      breadcrumbs: true,
      submodule: 'POSITION_AVAILABILTY_VALUES'
    }
  ]
};
