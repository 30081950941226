import { HelmetMeta } from '../../../layout/HelmetMeta';
import { AvailabilityValuePage } from '../../../sections/@availability-value/AvailabilityValuePage';
import { AvailabilityValuePageContextProvider } from '../../../sections/@availability-value/context/AvailabilityValuePageContext';

export const AvailabilityValuePageWrapper = () => (
  <AvailabilityValuePageContextProvider>
    <HelmetMeta pageName="Saldo Disponibilidade" />
    <AvailabilityValuePage />
  </AvailabilityValuePageContextProvider>
);
