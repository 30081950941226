import { CircularProgress, Typography } from '@mui/material';
import ReactEcharts, { EChartsReactProps } from 'echarts-for-react';
import { StyledCard, Subtitle, Title } from './styles';

export type BaseEchartsParams = EChartsReactProps & {
  title?: string;
  subtitle?: string;
  dataLength?: number;
  loading?: boolean;
};

export const BaseEcharts = ({ title, subtitle, style, dataLength = 0, loading = false, ...props }: BaseEchartsParams) => {

  const minHeight = 350;

  return (
    <StyledCard style={{minHeight: minHeight}}>
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
      {loading === true ? (
        <Typography variant="body1" align="center">
          <CircularProgress />
        </Typography>
      ) : dataLength === 0 ? (
        <Typography variant="body1" align="center">
          Nenhum dado encontrado.
        </Typography>
      ) : (
        <ReactEcharts {...props} style={{height: minHeight, ...style}}/>
      )}
    </StyledCard>
  );
};
