import { AxiosResponse, isAxiosError } from 'axios';
import createAxiosInstance from '../middlewares/axios.interceptors';

const PositionAvailabilityValueUrl = '/finance/position-availability-values/';

export const PositionAvailabilityValueService = {
  get: async () => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.get(PositionAvailabilityValueUrl);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  }
};
