import { yupResolver } from '@hookform/resolvers/yup';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Alert, Divider, Grid, InputLabel, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { ControlledTextInput } from '../../../components/basics/ControlledTextInput';
import { Availability } from '../../../models/Availability';
import { AvailabilityValue } from '../../../models/AvailabilityValue';
import { FormMessage } from '../../../models/FormMessage';
import { AvailabilityService } from '../../../services/Availability.service';
import { AvailabilityMonthValueFilterFields, AvailabilityMonthValueService } from '../../../services/AvailabilityMonthValue.service';
import { AvailabilityValueService } from '../../../services/AvailabilityValue.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { checkResponseStatus } from '../../../utils/api/response';
import { AvailabilityValueScheme } from '../../../utils/forms/validations/formValidations';
import { convertISOToDate, formatCurrency, formatDate, formatDateTimeToApi, setInputErrorsFromApi } from '../../../utils/utils';
import { AvailabilityFilterFields } from '../../@availability-page/context/AvailabilityPageContext';
import { useAvailabilityValuesPageContext } from '../context/AvailabilityValuePageContext';

interface Props {
  editItem?: AvailabilityValue;
  onClose: () => void;
}

const getLastDayOfPreviousMonth = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth();

  const lastDayOfPreviousMonth = new Date(year, month, 0);

  const formattedDate = lastDayOfPreviousMonth.toISOString().split('T')[0];

  return formattedDate;
};

export const AvailabilityValueFormDialog = ({ editItem, onClose }: Props) => {
  const dispatch = useDispatch();
  const [formMessage, setFormMessage] = useState<FormMessage | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const [availabilities, setAvailabilities] = useState<Availability[] | undefined>(undefined);
  const [availabilitiesFiltered, setAvailabilitiesFiltered] = useState<Availability[] | undefined>(undefined);

  const { fetchAvailabilityValues } = useAvailabilityValuesPageContext();

  const {
    control,
    handleSubmit,
    setError,
    setValue,
    formState: { errors }
  } = useForm<AvailabilityValue>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(AvailabilityValueScheme),
    defaultValues: editItem
      ? {
          ...editItem,
          date: editItem.date ? formatDate(editItem.date) : null
        }
      : {
          date: convertISOToDate(getLastDayOfPreviousMonth())
        }
  });

  const validateAndCleanValue = (rawValue: string) => {
    const isNegative = rawValue.includes('-');

    let cleanedValue = rawValue
      .replace(/^-?\s*R\$|\s/g, '')
      .replace(/\./g, '')
      .replace(',', '.');

    if (isNegative) {
      cleanedValue = `-${cleanedValue}`;
    }

    const value = parseFloat(cleanedValue);

    if (!isNaN(value)) {
      return value;
    } else {
      console.error(`Erro na conversão do valor: ${rawValue}`);
      return null;
    }
  };

  const onSubmitForm: SubmitHandler<AvailabilityValue> = async (data: AvailabilityValue) => {
    const availabilityValueItems = availabilitiesFiltered
      ?.map((availability) => {
        const rawValue = (data as any)[availability.id]?.toString().trim();

        if (rawValue) {
          const value = validateAndCleanValue(rawValue);

          if (value !== null) {
            return { availability: availability.id, value: value };
          }
        }
        return null;
      })
      .filter((item) => item !== null);
    const submissionData = {
      date: data?.date ? formatDateTimeToApi(data.date, true) : null,
      items: availabilityValueItems
    };

    dispatch(showSpinner());

    try {
      const response = editItem?.id
        ? await AvailabilityValueService.update({ id: editItem.id, ...submissionData })
        : await AvailabilityValueService.create(submissionData);

      if (response) {
        dispatch(
          showSnackbarAlert({
            title: 'Sucesso!',
            message: editItem?.id ? 'Saldo de disponibilidade atualizado com sucesso!' : 'Saldo de disponibilidade criado com sucesso!',
            severity: 'success'
          })
        );
      }
      fetchAvailabilityValues();
      onClose();
    } catch (error: any) {
      setFormMessage({
        severity: 'error',
        message: setInputErrorsFromApi(setError, error.data) || ''
      });
    } finally {
      dispatch(hideSpinner());
    }
  };

  const fetchAvailabilities = async () => {
    try {
      let response;
      if (editItem?.id) {
        response = await AvailabilityService.get({} as AvailabilityFilterFields);
      } else {
        response = await AvailabilityService.get({ situation: 'A' } as AvailabilityFilterFields);
      }
      if (checkResponseStatus(response)) {
        const result = response?.data?.results;
        setAvailabilities(result);
        if (!editItem?.id) {
          setAvailabilitiesFiltered(result);
        }
      }
    } catch (error: any) {
      dispatch(showSnackbarAlert({ title: 'Erro', message: error?.data || 'Houve um erro ao buscar Disponibilidades', severity: 'error' }));
    }
  };

  const fetchAvailabilityMonthValues = async () => {
    if (!editItem?.id || !availabilities) return;

    try {
      const response = await AvailabilityMonthValueService.get({ availability_month: editItem?.id } as AvailabilityMonthValueFilterFields);
      if (checkResponseStatus(response)) {
        const result = response?.data?.results;
        const filteredAvailabilities = availabilities.filter(
          (availability) =>
            result.some((item: { availability: number }) => item.availability === availability.id) || availability.situation === 'A'
        );

        setAvailabilitiesFiltered(filteredAvailabilities);

        result?.forEach((item: { availability: number; value: string }) => {
          setValue(`${item.availability}`, formatCurrency(parseFloat(item.value)));
        });
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: error?.data || 'Houve um erro ao buscar Saldos das Disponibilidades',
          severity: 'error'
        })
      );
    }
  };

  useEffect(() => {
    const loadData = async () => {
      dispatch(showSpinner());
      setLoading(true);

      await fetchAvailabilities();
    };

    loadData();
  }, []);

  useEffect(() => {
    const loadMonthValues = async () => {
      if (editItem?.id) {
        await fetchAvailabilityMonthValues();
      }
    };

    loadMonthValues();
  }, [availabilities]);

  useEffect(() => {
    if (availabilitiesFiltered) {
      setLoading(false);
      dispatch(hideSpinner());
    }
  }, [availabilitiesFiltered]);

  return (
    <Dialog open={!loading} onClose={onClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        {editItem?.id ? 'Editar' : 'Novo'} Saldo de Disponibilidade
      </DialogTitle>
      <Divider />
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <DialogContent>
          <Grid container spacing={2}>
            {formMessage?.message && (
              <Grid item xs={12}>
                <Alert severity={formMessage?.severity} variant="outlined" sx={{ width: '100%' }}>
                  {formMessage.message}
                </Alert>
              </Grid>
            )}
            <Grid item xs={12} md={3}>
              <InputLabel htmlFor="date">Data</InputLabel>
              <ControlledTextInput
                name="date"
                placeholder="Data"
                control={control}
                mask="99/99/9999"
                errorMessage={errors.date?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} md={9} />
            <Grid item xs={12} md={9}>
              <Typography variant="h4" color="primary">
                Disponibilidades:
              </Typography>
            </Grid>
            {availabilitiesFiltered?.map((availability, index) => (
              <>
                <Grid item xs={12} md={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12} md={8}>
                  <Typography>{availability.description}</Typography>
                </Grid>
                <Grid item xs={12} md={4} key={index}>
                  <ControlledTextInput
                    name={`${availability.id}`}
                    placeholder="Valor"
                    control={control}
                    defaultValue="0"
                    errorMessage={errors[`value-${index}`]?.message?.toString()}
                    currency={true}
                  />
                </Grid>
              </>
            ))}
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ mx: 2, my: 1 }}>
          <Button startIcon={<CloseIcon />} variant="outlined" onClick={onClose}>
            Cancelar
          </Button>
          <Button startIcon={<CheckIcon />} variant="contained" type="submit" onClick={handleSubmit(onSubmitForm)}>
            Salvar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
