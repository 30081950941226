import { toTitleCase } from '../utils/utils';
import { PermissionGroup } from './PermissionGroup';

export interface User {
  id?: number;
  username?: string;
  email?: string;
  first_name?: string;
  last_name?: string;
  password?: string;
  confirm_password?: string;
  is_active?: boolean;
  is_staff?: boolean;
  access_all_companies?: boolean;
  is_superuser?: boolean;
  date_joined?: string;
  last_login?: string;
  language?: string;
  session_permissions?: Permission[];
  permissions?: Permission[] | number[] | string[];
  profiles?: PermissionGroup[] | number[] | string[];
  groups?: PermissionGroup[] | number[] | string[];
}

export interface Permission {
  id: number;
  module: string;
  permission: string;
  module_label: string;
  permission_label: string;
  submodule: string;
  submodule_label: string;
}

export const getUserName = (user: User) => {
  return toTitleCase(`${user?.first_name} ${user?.last_name}`.trim() || user?.username || ' - ');
};
