import CheckIcon from '@mui/icons-material/Check';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import CloseIcon from '@mui/icons-material/Close';
import { Autocomplete, Divider, FormHelperText, Grid, InputLabel, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { ControlledTextInput } from '../../../components/basics/ControlledTextInput';
import { RDFunnel } from '../../../models/RDFunnel';
import { RDFunnelStage } from '../../../models/RDFunnelStage';
import { RDFunnelsService } from '../../../services/RDFunnel.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { SaleDivisionLinkFilterFields, useSaleDivisionLinksPageContext } from '../context/SaleDivisionLinkPageContext';
interface Props {
  onClose: () => void;
}

const getDefaultValues = (filter?: SaleDivisionLinkFilterFields): SaleDivisionLinkFilterFields => {
  return {
    sale_division_code: filter?.sale_division_code ?? '',
    sale_division_description: filter?.sale_division_description ?? '',
    focco_representative_trade_name: filter?.focco_representative_trade_name ?? ''
  };
};

export const SaleDivisionLinkFilterDialog = ({ onClose }: Props) => {
  const { filterFields, setFilterFields } = useSaleDivisionLinksPageContext();

  const dispatch = useDispatch();
  const [rdFunnels, setRDFunnels] = useState<RDFunnel[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedFunnelStage, setSelectedFunnelStage] = useState<RDFunnelStage | null>(null);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors }
  } = useForm<SaleDivisionLinkFilterFields>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: getDefaultValues(filterFields)
  });

  const onSubmit: SubmitHandler<SaleDivisionLinkFilterFields> = async (data: SaleDivisionLinkFilterFields) => {
    data = {
      ...data,
      funnel_stage: selectedFunnelStage ? selectedFunnelStage.stageId : null
    };
    setFilterFields({ ...data } as SaleDivisionLinkFilterFields);
    onClose();
  };

  const handleClear = () => {
    setFilterFields({} as SaleDivisionLinkFilterFields);
    onClose();
  };

  const fetchRDFunnels = async () => {
    try {
      const response = await RDFunnelsService.getAll();
      if (response?.data?.results) {
        const dataRDFunnels = response.data.results;
        setRDFunnels(
          dataRDFunnels.flatMap((funnel: RDFunnel) =>
            funnel.deal_stages.map((stage: RDFunnelStage) => ({
              funnelName: funnel.name,
              funnelId: funnel.id,
              stageName: stage.name,
              stageId: stage.id
            }))
          )
        );
      }
    } catch (error) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: 'Erro ao buscar Funis e Etapas do RD Station',
          severity: 'error'
        })
      );
    }
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      dispatch(showSpinner());

      await fetchRDFunnels();

      setIsLoading(false);
      dispatch(hideSpinner());
    })();
  }, []);

  useEffect(() => {
    if (filterFields && rdFunnels.length > 0) {
      const funnelStage = rdFunnels.find((stage) => stage.stageId === filterFields.funnel_stage);
      if (funnelStage) {
        setValue('funnel_stage', funnelStage);
        setSelectedFunnelStage(funnelStage);
      }
    }
  }, [rdFunnels]);

  return (
    <Dialog open={!isLoading} onClose={onClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        Filtros
      </DialogTitle>
      <Divider />
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="sale_division_code">Código Divisão de Venda</InputLabel>
              <ControlledTextInput
                name="sale_division_code"
                placeholder="Código Divisão de Venda"
                control={control}
                errorMessage={errors.sale_division?.code?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="sale_division_description">Descrição Divisão de Venda</InputLabel>
              <ControlledTextInput
                name="sale_division_description"
                placeholder="Descrição Divisão de Venda"
                control={control}
                errorMessage={errors.sale_division?.description?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="market_segment_description">Segmento de Mercado</InputLabel>
              <ControlledTextInput
                name="market_segment_description"
                placeholder="Segmento de Mercado"
                control={control}
                errorMessage={errors.market_segment?.description?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="market_segment_description">Segmento de Mercado</InputLabel>
              <ControlledTextInput
                name="market_segment_description"
                placeholder="Segmento de Mercado"
                control={control}
                errorMessage={errors.market_segment?.description?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="focco_representative_trade_name">Representante</InputLabel>
              <ControlledTextInput
                name="focco_representative_trade_name"
                placeholder="Representante"
                control={control}
                errorMessage={errors.focco_representative?.trade_name?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="funnel_stage">Etapa do Funil</InputLabel>
              <Controller
                name="funnel_stage"
                control={control}
                render={({ field: { onChange, value, ref } }) => (
                  <>
                    <Autocomplete
                      name="funnel_stage"
                      id="grouped-demo"
                      size="small"
                      options={rdFunnels.sort((a, b) => a.funnelName.localeCompare(b.funnelName))}
                      groupBy={(option) => option.funnelName}
                      getOptionLabel={(option) => option.stageName}
                      value={value}
                      onChange={(event, newValue) => {
                        onChange(newValue);
                        setSelectedFunnelStage(newValue);
                      }}
                      sx={{ width: '100%' }}
                      renderInput={(params) => <TextField {...params} inputRef={ref} placeholder="Etapa do Funil" />}
                    />
                    {errors.funnel_stage && <FormHelperText sx={{ color: 'red' }}>{errors.funnel_stage.message}</FormHelperText>}
                  </>
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ mx: 2, my: 1 }}>
          <Button startIcon={<ClearAllIcon />} variant="outlined" onClick={handleClear}>
            Limpar
          </Button>
          <Button startIcon={<CloseIcon />} variant="outlined" onClick={onClose}>
            Fechar
          </Button>
          <Button startIcon={<CheckIcon />} variant="contained" type="submit" onClick={handleSubmit(onSubmit)}>
            Filtrar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
