import { Card, Typography } from '@mui/material';
import styled from 'styled-components';

export const StyledCard = styled(Card)`
  padding: 2rem 1rem;
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

export const Title = styled(Typography).attrs({
  variant: 'h4',
  align: 'center'
})`
  font-size: 18px;
  text-align: center;
`;

export const Subtitle = styled(Typography).attrs({
  variant: 'h6',
  align: 'center'
})`
  font-size: 12px;
  text-align: center;
  color: #4D4D4D;
`;