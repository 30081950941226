import { Checkbox, CheckboxProps, Typography } from '@mui/material';
import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { CheckboxContainer } from './styles';

interface Props extends CheckboxProps {
  name: string;
  control: Control<any>;
  label?: string;
  defaultChecked?: boolean;
  errorMessage?: string;
  className?: string;
  handleOnChange?: () => void;
}

export const ControlledCheckbox: React.FC<Props> = ({
  name,
  control,
  label,
  className,
  defaultChecked = true,
  handleOnChange,
  ...rest
}: Props) => {
  const _renderInput = (onChange: (value: any) => void, value: any, name: string, onBlur: () => void, ref: React.Ref<HTMLInputElement>) => {
    return (
      <CheckboxContainer>
        <Checkbox
          checked={value}
          onChange={(event) => {
            onChange(event.target.checked);
            handleOnChange && handleOnChange();
          }}
          onBlur={onBlur}
          name={name}
          inputRef={ref}
          {...rest}
        />
        <Typography overflow="hidden">{label}</Typography>
      </CheckboxContainer>
    );
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultChecked}
      render={({ field: { onChange, value, name, onBlur, ref } }) => _renderInput(onChange, value, name, onBlur, ref)}
    />
  );
};
