import { Typography } from '@mui/material';
import { Difference, RecordDifference, SimpleRecord } from '../../models/IntegrationLog';

export const formatRecordDifference = (operation: string, recordDifference: RecordDifference): JSX.Element => {
  switch (operation) {
    case 'CREATE':
    case 'DELETE':
      return formatSimpleRecordDifference(recordDifference as SimpleRecord);
    case 'UPDATE':
      return formatComplexRecordDifference(recordDifference);
    default:
      return <Typography variant="body2">Nenhuma alteração</Typography>;
  }
};

const formatSimpleRecordDifference = (record: SimpleRecord): JSX.Element => (
  <div>
    {Object.entries(record).map(([key, value]) => (
      <Typography key={key} variant="body2">
        <strong>{key}:</strong> {String(value)}
      </Typography>
    ))}
  </div>
);

const formatComplexRecordDifference = (record: RecordDifference): JSX.Element => (
  <div>
    {Object.entries(record).map(([key, diff]) => (
      <div key={key}>
        <Typography variant="body2">
          <strong>{key}:</strong>
        </Typography>
        <Typography variant="body2" style={{ paddingLeft: 5 }}>
          <strong>de:</strong> {String((diff as Difference).old)}
        </Typography>
        <Typography variant="body2" style={{ paddingLeft: 5 }}>
          <strong>para:</strong> {String((diff as Difference).new)}
        </Typography>
      </div>
    ))}
  </div>
);
