import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import { ChangeEvent, MouseEvent, useEffect, useState } from 'react';
import { AlertComponent } from '../../../components/basics/AlertComponent';
import { DialogComponent } from '../../../components/basics/DialogComponent';
import { IconButtonHover } from '../../../components/basics/IconButtonHover';
import { StyledTableRow } from '../../../components/basics/StyledTableRow';
import { HeadCell, Order, TableHeaderComponent } from '../../../components/basics/TableHeaderComponent';
import { TableToolbar } from '../../../components/basics/TableToolbarComponent';
import { PermissionsObj } from '../../../hooks/useCrudPermissions';
import { User } from '../../../models/User';
import { formatDate, labelDisplayedRows } from '../../../utils/utils';
import { useUsersPageContext } from '../context/UsersPageContext';

const headCells: readonly HeadCell[] = [
  {
    id: 'username',
    align: 'left',
    disablePadding: true,
    label: 'Nome Usuário',
    disableSorting: false
  },
  {
    id: 'email',
    align: 'left',
    disablePadding: false,
    label: 'E-mail',
    disableSorting: false
  },
  {
    id: 'situation',
    align: 'left',
    disablePadding: false,
    label: 'Situação',
    disableSorting: true
  },
  {
    id: 'type',
    align: 'left',
    disablePadding: false,
    label: 'Tipo Usuário',
    disableSorting: true
  },
  {
    id: 'created_at',
    align: 'left',
    disablePadding: false,
    label: 'Criado Em',
    disableSorting: false
  },
  {
    id: 'actions',
    align: 'right',
    disablePadding: false,
    label: 'Ações',
    disableSorting: true
  }
];

interface Props {
  onView: (item: User) => void;
  onEdit: (item: User) => void;
  onEditPermission: (item: User) => void;
  permissions: PermissionsObj;
}

export const UsersTable = ({ onView, onEdit, onEditPermission, permissions }: Props) => {
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<string>('username');
  const [selected, setSelected] = useState<string[]>([]);

  const [deleteItem, setDeleteItem] = useState<User | undefined>(undefined);
  const [deleteMany, setDeleteMany] = useState<boolean>(false);

  const { users, page, rowsPerPage, handleOrderChange, handlePageChange, handleOnChangeRowsPerPage, deleteUser, deleteManyUsers } =
    useUsersPageContext();

  const handleDeleteItem = (item: User) => {
    setDeleteItem(item);
  };

  const handleDeleteSelectedItems = () => {
    setDeleteMany(true);
  };

  const handleDeleteConfirm = async () => {
    if (deleteItem?.id) {
      await deleteUser(deleteItem.id);
    } else if (deleteMany) {
      await deleteManyUsers(selected);
    }
    setDeleteMany(false);
    setDeleteItem(undefined);
    setSelected([]);
  };

  const handleDeleteCancel = () => {
    setDeleteMany(false);
    setDeleteItem(undefined);
  };

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(String(property));
    handleOrderChange(property, isAsc ? 'desc' : 'asc');
  };

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = users?.results.map((item: User) => String(item.id)) || [];
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected?.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    handlePageChange(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    handleOnChangeRowsPerPage(parseInt(event.target.value, 10));
  };

  const isSelected = (id: number) => selected.indexOf(id.toString()) != -1;

  useEffect(() => {
    setSelected([]);
  }, [users]);

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableToolbar numSelected={selected?.length} onDeleteSelected={handleDeleteSelectedItems} permissions={permissions} />
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'small'}>
            <TableHeaderComponent
              headCells={headCells}
              numSelected={selected?.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={users?.count || 0}
            />
            <TableBody>
              {users && users?.count > 0 ? (
                <>
                  {users?.results?.map((row: User, index: number) => {
                    const isItemSelected = isSelected(row?.id!);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <StyledTableRow
                        onClick={(event) => handleClick(event, String(row.id))}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        sx={{ cursor: 'pointer' }}
                      >
                        <TableCell padding="checkbox" align="left">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              'aria-labelledby': labelId
                            }}
                          />
                        </TableCell>
                        <TableCell component="th" id={labelId} scope="row" padding="none" align="left">
                          {row.username || '-'}
                        </TableCell>
                        <TableCell align="left">{row.email}</TableCell>
                        <TableCell align="left">
                          {row.is_active ? (
                            <AlertComponent severity="success">Ativo</AlertComponent>
                          ) : (
                            <AlertComponent severity="error">Inativo</AlertComponent>
                          )}
                        </TableCell>
                        <TableCell align="left">{row.is_staff ? 'Interno' : 'Cliente'}</TableCell>
                        <TableCell align="left">{formatDate(String(row.date_joined))}</TableCell>
                        <TableCell align="right">
                          {permissions.hasReadPermission && <IconButtonHover row={row} onClick={onView} type="view" />}
                          {permissions.hasUpdatePermission && (
                            <>
                              <IconButtonHover row={row} onClick={onEdit} type="edit" />
                              <IconButtonHover row={row} onClick={onEditPermission} type="permission" disabled={!row.is_staff} />
                            </>
                          )}
                          {permissions.hasDeletePermission && <IconButtonHover row={row} onClick={handleDeleteItem} type="delete" />}
                        </TableCell>
                      </StyledTableRow>
                    );
                  })}
                </>
              ) : (
                <StyledTableRow>
                  <TableCell align="center" colSpan={6}>
                    Nenhum registro encontrado
                  </TableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {users && users?.count > 0 && (
          <TablePagination
            rowsPerPageOptions={[5, 25, 50]}
            component="div"
            count={users?.count || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Linhas por página"
            labelDisplayedRows={labelDisplayedRows}
          />
        )}
        {(deleteItem || deleteMany) && (
          <DialogComponent
            title={deleteItem ? 'Excluir Usuário' : 'Excluir Usuários'}
            description={deleteItem ? 'Tem certeza que deseja excluir esse usuário?' : 'Tem certeza que deseja excluir esses usuários?'}
            onCancel={handleDeleteCancel}
            onConfirm={handleDeleteConfirm}
          />
        )}
      </Paper>
    </Box>
  );
};
