import RefreshIcon from '@mui/icons-material/Refresh';
import { Button, Tooltip } from '@mui/material';
import { useCrudPermissions } from '../../../hooks/useCrudPermissions';
import { PositionAvailabilityTable } from '../PositionAvailabilityValueTable';
import { usePositionAvailabilityValuesContext } from '../context/PositionAvailabilityValuePageContext';
import { ButtonsContainer } from './styles';

export const PositionAvailabilityValuePage = () => {
  const { permissions } = useCrudPermissions({
    submodule: 'POSITION_AVAILABILTY_VALUES'
  });

  const { fetchPositionAvailabilityValues } = usePositionAvailabilityValuesContext();

  const handleRefresh = () => {
    fetchPositionAvailabilityValues();
  };

  return (
    <>
      <ButtonsContainer>
        {permissions.hasReadPermission && (
          <>
            <Tooltip title="Filtros">
              <Button variant="outlined" color="primary" startIcon={<RefreshIcon />} sx={{ mr: 1 }} onClick={handleRefresh}>
                Atualizar
              </Button>
            </Tooltip>
          </>
        )}
      </ButtonsContainer>
      <PositionAvailabilityTable />
    </>
  );
};
