import React, { createContext, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ApiResults } from '../../../models/ApiResults';
import { AvailabilityValue } from '../../../models/AvailabilityValue';
import { AvailabilityValueService } from '../../../services/AvailabilityValue.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { checkResponseStatus } from '../../../utils/api/response';

export interface AvailabilityValueFilterFields extends AvailabilityValue {
  order_by?: string;
  page?: number;
  page_size?: number;
  search?: string;
}

export interface AvailabilityValueResult extends ApiResults {
  results: AvailabilityValue[];
}

interface AvailabilityValueContextProps {
  availabilityValues: AvailabilityValueResult | undefined;
  fetchAvailabilityValues: () => void;
  deleteAvailabilityValues: (id: number) => Promise<void>;
  deleteManyAvailabilityValues: (items: string[]) => Promise<void>;
  filterFields: AvailabilityValueFilterFields;
  setFilterFields: React.Dispatch<React.SetStateAction<AvailabilityValueFilterFields>>;
  page: number;
  rowsPerPage: number;
  handleOrderChange: (property: string, order: 'asc' | 'desc') => void;
  handlePageChange: (page: number) => void;
  handleOnChangeRowsPerPage: (page_size: number) => void;
}

const AvailabilityValuesPageContext = createContext<AvailabilityValueContextProps | undefined>(undefined);

interface Props {
  children: React.ReactNode;
}

export const AvailabilityValuePageContextProvider = ({ children }: Props) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(50);
  const [availabilityValues, setAvailabilityValues] = useState<AvailabilityValueResult | undefined>(undefined);
  const [filterFields, setFilterFields] = useState<AvailabilityValueFilterFields>({} as AvailabilityValueFilterFields);

  const fetchAvailabilityValues = async () => {
    dispatch(showSpinner());

    try {
      const response = await AvailabilityValueService.get(filterFields, page, rowsPerPage);
      if (checkResponseStatus(response)) {
        setAvailabilityValues(response?.data);
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({ title: 'Erro', message: error?.data || 'Houve um erro ao processar a sua solicitação', severity: 'error' })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const deleteAvailabilityValues = async (id: number) => {
    dispatch(showSpinner());
    try {
      await AvailabilityValueService.delete(id);
      dispatch(
        showSnackbarAlert({
          title: 'Sucesso',
          message: 'Registro excluído com sucesso!',
          severity: 'success'
        })
      );
      fetchAvailabilityValues();
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: error.data.detail || 'Houve um erro ao processar a sua solicitação',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const deleteManyAvailabilityValues = async (items: string[]) => {
    dispatch(showSpinner());
    try {
      await AvailabilityValueService.deleteMany(items);
      dispatch(
        showSnackbarAlert({
          title: 'Sucesso',
          message: 'Registros excluídos com sucesso!',
          severity: 'success'
        })
      );
      fetchAvailabilityValues();
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: error.data.detail ?? 'Houve um erro ao processar a sua solicitação',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const handleOrderChange = (property: string, order: 'asc' | 'desc') => {
    const orderFilter = order === 'asc' ? property : `-${property}`;
    setFilterFields({ ...filterFields, order_by: orderFilter });
  };

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handleOnChangeRowsPerPage = (page_size: number) => {
    setRowsPerPage(page_size);
  };

  useEffect(() => {
    fetchAvailabilityValues();
  }, [filterFields, page, rowsPerPage]);

  const contextValue: AvailabilityValueContextProps = {
    availabilityValues,
    fetchAvailabilityValues,
    deleteAvailabilityValues,
    deleteManyAvailabilityValues,
    filterFields,
    setFilterFields,
    page,
    rowsPerPage,
    handleOrderChange,
    handlePageChange,
    handleOnChangeRowsPerPage
  };

  return <AvailabilityValuesPageContext.Provider value={contextValue}>{children}</AvailabilityValuesPageContext.Provider>;
};

export const useAvailabilityValuesPageContext = () => {
  const context = useContext(AvailabilityValuesPageContext);
  if (!context) {
    throw new Error('useAvailabilityValuesPageContext must be used within a AvailabilityValuesPageContextProvider');
  }
  return context;
};
