// material-ui
import { Box, IconButton, Toolbar, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// project import

// assets
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { Profile } from './Profile';
import { AppBarStyled } from './styles';

// ==============================|| MAIN LAYOUT - HEADER ||============================== //
interface Props {
  open: boolean;
  handleDrawerToggle: () => void;
}

export const Header = ({ open, handleDrawerToggle }: Props) => {
  const theme = useTheme();
  const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));

  const mainHeader = (
    <Toolbar>
      <Box sx={{ display: 'flex', flex: 1, justifyContent: 'space-between' }}>
        <IconButton
          disableRipple
          aria-label="open drawer"
          onClick={handleDrawerToggle}
          edge="start"
          color="secondary"
          sx={{ color: 'common.white' }}
        >
          {!open ? <MenuIcon fontSize="small" /> : <MenuOpenIcon fontSize="small" />}
        </IconButton>
        <Profile />
      </Box>
    </Toolbar>
  );

  //ESTILOS DA BARRA DO HEADER
  const appBar = {
    position: 'fixed',
    color: 'inherit',
    sx: {
      backgroundColor: theme.palette.primary.main,
      boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.2)'
    }
  };

  return (
    <AppBarStyled open={open} ismobile={matchDownMD} {...appBar}>
      {mainHeader}
    </AppBarStyled>
  );
};
