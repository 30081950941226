import { yupResolver } from '@hookform/resolvers/yup';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Divider,
  Grid,
  InputLabel,
  Stack,
  Typography,
  useTheme
} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { ControlledComboBox } from '../../../components/basics/ControlledComboBox';
import { ControlledTextInput } from '../../../components/basics/ControlledTextInput';
import { FormMessage } from '../../../models/FormMessage';
import { PermissionModule } from '../../../models/Permission';
import { PermissionGroup } from '../../../models/PermissionGroup';
import { PermissionGroupsService } from '../../../services/PermissionGroups.service';
import { PermissionsService } from '../../../services/Permissions.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { PermissionGroupSchema } from '../../../utils/forms/validations/formValidations';
import { hexToRgba, setInputErrorsFromApi } from '../../../utils/utils';
import { usePermissionGroupsPageContext } from '../context/PermissionGroupsContext';

interface Props {
  editItem?: PermissionGroup;
  onClose: () => void;
}

export const PermissionGroupsFormDialog = ({ editItem, onClose }: Props) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { setShouldRefresh } = usePermissionGroupsPageContext();

  const [loading, setLoading] = useState<boolean>(true);

  const [formMessage, setFormMessage] = useState<FormMessage | null>(null);
  const [permissionStructure, setPermissionStructure] = useState<PermissionModule[]>([]);

  const {
    control,
    handleSubmit,
    setError,
    reset,
    formState: { errors }
  } = useForm<any>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(PermissionGroupSchema)
  });

  const watchModule = useWatch({
    control,
    name: 'permissionModule'
  });

  const handleCreateOrUpdate: SubmitHandler<any> = async (data: any) => {
    dispatch(showSpinner());
    try {
      let permissionsIds: number[] = [];

      //se o módulo no index tiver permissão full, então tem que ler todos os submódulos e permissões e guardar os ids das permissões
      data.permissionModule.forEach((permissionModule: any, index: number) => {
        if (permissionModule?.type?.id === 'full') {
          console.log('full module', permissionModule);
          permissionStructure[index].submodules.forEach((submodule) => {
            submodule.permissions.forEach((permission) => {
              permissionsIds.push(permission.id!);
            });
          });
        }
      });

      //se o módulo tiver permissão specific, então tem que ler os submódulos e permissões selecionados e guardar os ids das permissões
      data.permissionModule.forEach((permissionModule: any) => {
        if (permissionModule?.type?.id === 'specific') {
          permissionModule.submodule.forEach((submodule: any) => {
            submodule.forEach((permission: any) => {
              permissionsIds.push(permission.id);
            });
          });
        }
      });

      data.permissions = permissionsIds;

      editItem?.id ? await PermissionGroupsService.update(editItem.id, data) : await PermissionGroupsService.create(data);

      dispatch(
        showSnackbarAlert({
          title: 'Sucesso',
          message: 'Grupo de Permissões salvo com sucesso',
          severity: 'success'
        })
      );
      setShouldRefresh(true);
      onClose();
    } catch (error: any) {
      const formError = setInputErrorsFromApi(setError, error.data);
      if (formError) {
        setFormMessage({
          severity: 'error',
          message: formError
        });
      }
    } finally {
      dispatch(hideSpinner());
    }
  };

  const handleClose = () => {
    onClose();
  };

  const fetchPermissionsStructure = async () => {
    dispatch(showSpinner());
    try {
      const response = await PermissionsService.get();
      if (response?.data?.results) {
        const permissions = response?.data?.results;
        setPermissionStructure(permissions);
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: 'Erro ao buscar permissões',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  useEffect(() => {
    fetchPermissionsStructure();
  }, []);

  useEffect(() => {
    //este useEffect popula os campos com os valores default que vem da API.

    let permissionsArray: any = {
      permissionModule: []
    };

    //para cada módulo
    permissionStructure.forEach((moduleItem, index) => {
      let fullPermissions = true;
      let hasSpecificPermissions = false;

      permissionsArray.permissionModule[index] = {
        type: { id: 'none', name: 'Sem Permissão' },
        submodule: []
      };

      // For each submodule
      moduleItem.submodules.forEach((submodule, subIndex) => {
        permissionsArray.permissionModule[index].submodule[subIndex] = [];

        // For each permission
        submodule.permissions.forEach((permission, permissionIndex) => {
          if (editItem?.permissions?.find((item: any) => item.id == permission.id)) {
            permissionsArray.permissionModule[index].submodule[subIndex].push({
              id: permission.id,
              name: permission.permission_label
            });
          } else {
            fullPermissions = false;
          }
        });
      });

      permissionsArray.permissionModule[index].submodule.forEach((submodule: any) => {
        if (submodule.length > 0) {
          hasSpecificPermissions = true;
        }
      });

      // Determine the type based on the presence of permissions
      if (fullPermissions) {
        permissionsArray.permissionModule[index].type = { id: 'full', name: 'Gerenciamento Completo' };
      } else if (hasSpecificPermissions) {
        permissionsArray.permissionModule[index].type = { id: 'specific', name: 'Permissões Específicas' };
      } else {
        permissionsArray.permissionModule[index].type = { id: 'none', name: 'Sem Permissão' };
      }
    });

    permissionsArray.name = editItem?.name;
    reset(permissionsArray);
    setLoading(false);
  }, [permissionStructure, editItem]);

  return (
    <Dialog open={true} onClose={handleClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        {editItem?.id ? 'Editar' : 'Novo'} Grupo de Permissões
      </DialogTitle>
      <Divider />
      <form onSubmit={handleSubmit(handleCreateOrUpdate)}>
        {!loading && (
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {formMessage?.message && (
                  <Grid item xs={12}>
                    <Alert severity={formMessage?.severity} variant="outlined" sx={{ width: '100%' }}>
                      {formMessage.message}
                    </Alert>
                  </Grid>
                )}
              </Grid>
              <Grid item xs={12}>
                <InputLabel htmlFor="name" sx={{ ml: 1 }}>
                  Nome do Grupo
                </InputLabel>
                <ControlledTextInput
                  name="name"
                  placeholder="Nome do grupo"
                  control={control}
                  errorMessage={errors.name?.message?.toString()}
                />
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ display: 'flex', flexDirection: 'column', pt: 1 }}>
                  <Box sx={{ ml: 1, pb: 1 }}>
                    <Typography variant="body1">Selecione os módulos que o usuário terá acesso.</Typography>
                    <Typography variant="body1" sx={{ color: theme.palette.text.secondary }}>
                      Você pode selecionar as permissões de cada módulo e submódulo.
                    </Typography>
                  </Box>
                  <Divider />
                  <Box>
                    {permissionStructure.map((moduleItem, index) => (
                      <Accordion key={index} expanded={watchModule?.[index]?.type?.id == 'specific'} elevation={0}>
                        <AccordionSummary
                          aria-controls={`panel${index}-content`}
                          id={`panel${index}-header`}
                          sx={{
                            backgroundColor:
                              watchModule?.[index]?.type?.id == 'full'
                                ? hexToRgba(theme.palette.success.light, 0.08)
                                : watchModule?.[index]?.type?.id == 'specific'
                                  ? hexToRgba(theme.palette.info.main, 0.1)
                                  : 'inherit',
                            '&.Mui-expanded': {
                              backgroundColor: 'inherit' // Override the expanded background color
                            }
                          }}
                        >
                          <Stack sx={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
                            <Box sx={{ display: 'flex', flex: 1, alignItems: 'center' }}>
                              <Typography variant="body1">{moduleItem.module_label}</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', flex: 0.5 }}>
                              <ControlledComboBox
                                variant="standard"
                                control={control}
                                name={`permissionModule.${index}.type`}
                                label="Permissão"
                                defaultValue={{ id: 'none', name: 'Sem Permissão' }}
                                selectOptions={[
                                  { id: 'none', name: 'Sem Permissão' },
                                  { id: 'specific', name: 'Permissões Específicas' },
                                  { id: 'full', name: 'Gerenciamento Completo' }
                                ]}
                              />
                            </Box>
                          </Stack>
                        </AccordionSummary>
                        <Divider />
                        <AccordionDetails>
                          <Box sx={{ p: 2 }}>
                            <Stack flexDirection="row" justifyContent="space-between" mt={2}>
                              <Box sx={{ display: 'flex', flex: 1 }}>
                                <Typography variant="body1" sx={{ textDecoration: 'underline' }}>
                                  Submódulos
                                </Typography>
                              </Box>
                              <Box sx={{ display: 'flex', flex: 1 }}>
                                <Typography variant="body1" sx={{ textDecoration: 'underline' }}>
                                  Permissões
                                </Typography>
                              </Box>
                            </Stack>
                            {moduleItem.submodules.map((submodule, subIndex) => (
                              <Stack key={subIndex} sx={{ display: 'flex', flexDirection: 'row', mt: 4 }} divider={<Divider />}>
                                <Box sx={{ display: 'flex', flex: 1, alignItems: 'center' }}>
                                  <Typography>{submodule.submodule_label}</Typography>
                                </Box>

                                <Box sx={{ display: 'flex', flex: 1 }}>
                                  <ControlledComboBox
                                    variant="standard"
                                    control={control}
                                    name={`permissionModule.${index}.submodule.${subIndex}`}
                                    label="Permissão"
                                    multiple
                                    selectOptions={submodule.permissions.map((permission) => ({
                                      id: permission.id,
                                      name: permission.permission_label
                                    }))}
                                  />
                                </Box>
                              </Stack>
                            ))}
                          </Box>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
        )}

        <Divider />
        <DialogActions sx={{ mx: 2, my: 1 }}>
          <Button startIcon={<CloseIcon />} variant="outlined" onClick={handleClose}>
            Cancelar
          </Button>
          <Button startIcon={<CheckIcon />} variant="contained" type="submit" onClick={handleSubmit(handleCreateOrUpdate)}>
            Salvar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
