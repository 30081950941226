import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BaseEcharts } from '../../../components/charts/BaseEcharts';
import { NetRevenueByChannelService } from '../../../services/Reports.service';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { formatValue, formatValueToBRL, sumAndFormatValuesToBRL, ellipsisWord } from '../utils';

export const NetRevenueByChannel = () => {
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();

  const options = {
    grid: {
      left: '15%',
      right: '15%'
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: { type: 'shadow' },
      valueFormatter: formatValueToBRL
    },
    xAxis: {
      type: 'category',
      data: data.map((item) => item.channel),
      boundaryGap: false,
      axisLabel: {
        show: true,
        interval: 0,
        rotate: 45,
        formatter: ellipsisWord
      },
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        show: false
      }
    },
    series: [
      {
        data: data.map((item) => item.value),
        type: 'bar',
        itemStyle: {
          color: '#14993B'
        },
        label: {
          show: true,
          position: 'top',
          fontSize: 10,
          formatter: (params: any) => formatValue(params.value)
        }
      }
    ]
  };

  const fetchReport = async () => {
    setLoading(true);
    try {
      const response = await new NetRevenueByChannelService().listAll();
      if (response?.data?.results) {
        setData(response.data.results.report);
      }
    } catch (error: any) {
      const errorMessage = error?.data?.detail || 'Erro ao buscar dados do Gráfico';
      dispatch(
        showSnackbarAlert({
          title: 'Erro!',
          message: errorMessage,
          severity: 'error'
        })
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchReport();
  }, []);

  return (
    <BaseEcharts
      title='Total de Faturamento por Canal (-Devolução)'
      subtitle={`Total: ${sumAndFormatValuesToBRL(data)}`}
      option={options}
      dataLength={data.length}
      loading={loading}
    />
  );
};
