import { createSlice } from '@reduxjs/toolkit';

interface GlobalSpinnerReducer {
  loading: boolean;
  message?: string;
}

const initialState: GlobalSpinnerReducer = {
  loading: false,
  message: ''
};

export const globalSpinnerSlice = createSlice({
  name: 'globalSpinner',
  initialState,
  reducers: {
    showSpinner: (state, action: { payload: { message?: string } | undefined }) => {
      state.loading = true;
      state.message = action?.payload?.message || 'Aguarde um momento';
    },
    hideSpinner: (state) => {
      state.loading = false;
      state.message = '';
    }
  }
});

export const { showSpinner, hideSpinner } = globalSpinnerSlice.actions;
export default globalSpinnerSlice.reducer;
