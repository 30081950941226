import { configureStore } from '@reduxjs/toolkit';
import globalSpinnerReducer from './slicers/globalSpinner.slicer';
import menuReducer from './slicers/menu.slicers';
import snackbarAlertReducer from './slicers/snackbarAlert.slicer';

export const store = configureStore({
  reducer: {
    menu: menuReducer,
    globalSpinner: globalSpinnerReducer,
    snackbarAlert: snackbarAlertReducer
  }
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
