export interface IntegrationLog {
  id: number;
  operation_time: string;
  operation: 'CREATE' | 'UPDATE' | 'DELETE' | 'ERROR';
  model: string;
  model_id: number;
  record_difference: RecordDifference;
  error_traceback: string;
}

export interface SimpleRecord {
  [key: string]: any;
}

export interface Difference {
  old: any;
  new: any;
}

export type RecordDifference = SimpleRecord | { [key: string]: Difference };

export enum OperationEnum {
  CREATE = 'Criação',
  UPDATE = 'Atualização',
  DELETE = 'Exclusão',
  ERROR = 'Erro'
}

export enum ModelsEnum {
  RepresentativeRelationship = 'Vínculo de Representante',
  SaleDivisionRelationship = 'Vínculo de Divisão de Venda',
  CustomerRelationship = 'Vínculo de Cliente',
  ProductRelationship = 'Vínculo de Produto',
  BudgetRelationship = 'Vínculo de Orçamento',
  Budget = 'Orçamento'
}
export const DEFAULT_MODEL = '-';

export function getModelDisplay(modelKey: string): string {
  return ModelsEnum[modelKey as keyof typeof ModelsEnum] || DEFAULT_MODEL;
}
