import { Helmet } from "react-helmet";
import { author, portalName } from "../../config";

type Props = {
  pageName?: string;
}

export const HelmetMeta = ({ pageName }: Props) => {

  const title = `${portalName} | ${author}`;
  const description = `${portalName} | Powered by ${author}.`;

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{pageName ? `${pageName} | ${portalName}` : portalName}</title>
      <meta name="title" content={title} />
      <meta name="description" content={description} />

      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
    </Helmet>
  )
}